import styled from 'styled-components'

import {componentTheme} from '../_utils'
import {Box} from '../Box'

export const InlineBlock = styled(Box)`
  display: inline-block;

  ${componentTheme('InlineBlock')}
`
