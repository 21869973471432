declare global {
  interface Window {
    PerformanceObserver?: any
    CONFIG: {
      apiUrl: string
      clientId: string
      googleAnalyticsId: string
      googleMapsAPIKey: string
      okta: {
        baseUrl: string
        issuerUrl: string
      }
      bugsnag: {
        key: string
        releaseStage: 'development' | 'staging' | 'production' | 'production-preview'
      }
      isCI: boolean
      skipAuthEmails: boolean
      useES: boolean
    }
    Cypress: any
    enableTours?: boolean
  }
}
const config = window.CONFIG || {
  apiUrl: process.env.REACT_APP_API_URL,
  clientId: process.env.REACT_APP_CLIENT_ID,
  googleAnalyticsId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID || '',
  googleMapsAPIKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  okta: {
    baseUrl: process.env.REACT_APP_OKTA_BASE_URL,
    issuerUrl: process.env.REACT_APP_OKTA_ISSUER_URL,
  },
  bugsnag: {
    key: '79e7c777c30a4ebf924ca740e50736d6',
    releaseStage: process.env.REACT_APP_BUGSNAG_RELEASE_STAGE || 'development',
  },
  isCI: !!process.env.REACT_APP_CI,
  skipAuthEmails: !!process.env.REACT_APP_SKIP_AUTH_EMAILS,
  useES: !!process.env.REACT_APP_DEFAULT_ELASTICSEARCH_ENABLED,
}

export default config
