import {StandardProperties} from 'csstype'
import * as React from 'react'
import {ThemeProvider} from 'styled-components'

import * as colorDefinitions from './colors'

export const colors = colorDefinitions

export interface ComponentCSSOverrides {
  Box?: string
  Button?: string
  Checkbox?: string
  Hidden?: string
  Icon?: string
  InlineBlock?: string
  Link?: string
  ListModule?: string
  Module?: string
  ModuleFooter?: string
  ModuleHeader?: string
  ModuleTitle?: string
  Popover?: string
  PopoverArrow?: string
  TitleBar?: string
  TitleBarSubtitle?: string
  TitleBarTitle?: string
  Trigger?: string
  PopupMenu?: string
}

interface Palette {
  base: string
  light: string
  dark: string
}

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
interface DefaultStyles extends Omit<StandardProperties, 'boxShadow'> {
  boxShadow: Palette
}

export interface ThemeInterface {
  components: ComponentCSSOverrides
  defaultStyles: DefaultStyles
  typography: {
    fontFamilies: {
      primary: string
      accent: string
    }
    fontSizes: {
      xs: string
      sm: string
      md: string
      lg: string
      xl: string
      base: string
    }
    lineHeights: {
      xs: string
      sm: string
      md: string
      lg: string
      xl: string
      base: string
    }
  }
  videoPlaceholderUrl: string
}

export interface ProviderProps {
  theme: ThemeInterface
}

export class Provider extends React.Component<ProviderProps> {
  render() {
    const {theme, children} = this.props
    return (
      <ThemeProvider theme={theme}>
        <>{children}</>
      </ThemeProvider>
    )
  }
}
