import React, { FC } from 'react';

import { StatefulTooltip, StatefulTooltipProps } from 'baseui/tooltip';
import { PopoverOverrides } from 'baseui/popover';
import { neutralColors } from '../../design/colors';

const { neutral1 } = neutralColors;

const borderRadius = '8px';

const getOverrides = (className?: string): PopoverOverrides => {
  return {
    // need to override both elements for border-radius
    Body: {
      style: {
        borderTopLeftRadius: borderRadius,
        borderTopRightRadius: borderRadius,
        borderBottomLeftRadius: borderRadius,
        borderBottomRightRadius: borderRadius,
        // give minor boost to precedence over items such as ad text blur container
        zIndex: 10,
        backgroundColor: neutral1,
      },
      props: {
        className,
      },
    },
    Inner: {
      style: {
        borderTopLeftRadius: borderRadius,
        borderTopRightRadius: borderRadius,
        borderBottomLeftRadius: borderRadius,
        borderBottomRightRadius: borderRadius,
        backgroundColor: neutral1,
      },
    },
    Arrow: {
      style: {
        backgroundColor: neutral1,
      },
    },
  };
};

interface Props extends StatefulTooltipProps {
  className?: string;
}

const Tooltip: FC<Props> = props => {
  const { className, showArrow = true, ...baseProps } = props;

  const overrides = getOverrides(className);

  return (
    <StatefulTooltip
      overrides={overrides}
      showArrow={showArrow}
      {...baseProps}
    ></StatefulTooltip>
  );
};
export default Tooltip;
