import React, { FC } from 'react';
import {
  Skeleton as BaseSkeleton,
  SkeletonPropsT,
  SkeletonOverrides,
} from 'baseui/skeleton';

interface Props extends SkeletonPropsT {
  className?: string;
}

const getOverrides = (className: string): SkeletonOverrides => {
  return {
    Root: {
      props: {
        className,
      },
    },
  };
};

const Skeleton: FC<Props> = props => {
  const { className = '', ...baseProps } = props;

  const overrides = getOverrides(className);
  return (
    <BaseSkeleton animation overrides={overrides} {...baseProps}></BaseSkeleton>
  );
};

export default Skeleton;
