import * as React from 'react'
import styled from 'styled-components'

import {colors} from '@ui/theme'

import {ifProp} from '../_utils'

const HiddenCheckbox = styled.input.attrs({type: 'checkbox'})`
  display: none;
`

interface IToggleableProps {
  checked?: boolean
  disabled?: boolean
}

const ToggleSwitchContainer = styled.div<IToggleableProps>`
  visibility: visible;
  position: relative;
  display: block;
  width: 34px;
  height: 20px;
  background-color: ${ifProp('checked', colors.patina, colors.satinlinen)};
  border: 1px solid ${ifProp('checked', colors.patina, colors.satinlinen)};
  border-radius: 20px;
  cursor: ${ifProp('disabled', 'not-allowed', 'pointer')};
  user-select: none;
  opacity: ${ifProp('disabled', 0.7, 1)};
`

const ToggleSwitchHandle = styled.div<IToggleableProps>`
  margin-left: ${ifProp('checked', '14px', '0')};
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: ${colors.white};
  box-shadow: ${props => props.theme.defaultStyles.boxShadow.base};
  opacity: ${ifProp('disabled', 0.7, 1)};
`

interface IToggle {
  checked?: boolean
  onCheck?: (value: boolean) => void
  name: string
  className?: string
  disabled?: boolean
}

export class Toggle extends React.Component<IToggle, any> {
  constructor(props) {
    super(props)
    this.state = {
      checked: false,
    }
    this.handleChange = this.handleChange.bind(this)
  }

  static defaultProps: any = {
    className: '',
    disabled: false,
    onCheck: () => null,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.checked !== nextProps.checked) {
      return {
        checked: nextProps.checked,
      }
    }
    return null
  }

  handleChange() {
    const {onCheck, disabled} = this.props
    if (!disabled) {
      const checked = !this.state.checked
      this.setState({checked})
      if (typeof onCheck === 'function') {
        onCheck(checked)
      }
    }
  }

  render() {
    return (
      <ToggleSwitchContainer
        className={this.props.className}
        checked={this.state.checked}
        onClick={this.handleChange}
        disabled={this.props.disabled}
      >
        <HiddenCheckbox
          data-testid={this.props['data-testid']}
          readOnly
          aria-checked={this.state.checked}
          checked={this.state.checked}
        />
        <ToggleSwitchHandle checked={this.state.checked} disabled={this.props.disabled} />
      </ToggleSwitchContainer>
    )
  }
}
