import React, { FC } from 'react';
import {
  Modal as BaseModal,
  ModalBody,
  ModalHeader,
  ModalProps,
  ROLE,
  SIZE,
} from 'baseui/modal';

const borderRadius = {
  borderTopLeftRadius: '4px',
  borderTopRightRadius: '4px',
  borderBottomLeftRadius: '4px',
  borderBottomRightRadius: '4px',
};

const getOverrides = (width?: string) => {
  return {
    Root: {
      style: {
        zIndex: 2000,
        ...borderRadius,
      },
    },
    Dialog: {
      style: {
        width,
        ...borderRadius,
      },
    },
    DialogContainer: {
      style: {
        maxHeight: 800,
        ...borderRadius,
      },
    },
  };
};

export interface Props extends ModalProps {
  title: string;
  width?: string;
}

const Modal: FC<Props> = props => {
  const {
    title,
    children,
    width,
    size = SIZE.full,
    role = ROLE.dialog,
    ...baseProps
  } = props;

  const overrides = getOverrides(width);
  return (
    <BaseModal
      overrides={overrides}
      closeable
      animate
      size={size}
      role={role}
      {...baseProps}
    >
      <ModalHeader style={{ marginTop: 15 }}>{title}</ModalHeader>
      <ModalBody style={{ marginBottom: 10 }}>{children}</ModalBody>
    </BaseModal>
  );
};

export default Modal;
