import React from 'react'
import styled from 'styled-components'

import {colors} from '@ui/theme'

const LogoContainer = styled.div`
  align-content: center;
  background: ${colors.riverbed};
  display: flex;
  justify-content: center;
  padding: 15px 0 16px;
  width: 310px;
`
const BlockLogo = styled.img`
  display: block;
  height: 22px;
`

const SpotlightLogo: React.FC = () => (
  <LogoContainer>
    <BlockLogo src="//spotlight-public-assets.s3.amazonaws.com/images/spotlight_logo.png" alt="Spotlight" />
  </LogoContainer>
)

export default SpotlightLogo
