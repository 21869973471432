import styled from 'styled-components'

import {componentTheme} from '../_utils'
import {colors} from '../theme'

export const ModuleFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 10px;
  padding: 10px 0 0 0;
  border-top: 2px solid ${colors.satinlinen};
  font-size: 14px;

  ${componentTheme('ModuleFooter')}
`

ModuleFooter.displayName = 'ModuleFooter'
