import React from 'react'
import Spinner from 'react-spinkit'
import styled from 'styled-components'

import {colors} from '@ui/theme'

import AuthLayout from '../components/layouts/AuthLayout'

const LoadingSpinner = styled(Spinner)`
  && {
    height: 28px;
    margin: 6px;
    width: 28px;
  }
`

export const FullScreenLoading: React.FC = () => (
  <AuthLayout>
    <LoadingSpinner name="circle" fadeIn="none" color={colors.hitgray} />
  </AuthLayout>
)
