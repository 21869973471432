import styled from 'styled-components'

import {colors} from '@ui/theme'

import {componentTheme} from '../_utils'
import {Box, BoxProps} from '../Box'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ButtonProps<T = HTMLButtonElement> extends BoxProps<T> {}

export const Button = styled(Box).attrs({element: 'button'})<ButtonProps>`
  ${componentTheme('Button')}
`

Button.defaultProps = {
  opaque: true,
  palette: 'primary',
}

export const BasicButton = styled(Button)`
  background-color: ${colors.patina};
  border: none;
  border-radius: 4px;
  color: #fff;
  display: flex;
  display: inline-flex;
  font-size: 12px;
  height: 44px;
  justify-content: space-around;
  letter-spacing: 1px;
  line-height: 44px;
  margin-top: 20px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  &:disabled {
    background-color: ${colors.cottonseed};
  }
  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
`

export const InlineButton = styled(BasicButton)`
  width: auto;
  display: inline-block;
  padding: 0 16px;
  margin: 0;
`
