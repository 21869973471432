import styled from 'styled-components'

import {componentTheme} from '../_utils'
import {colors} from '../theme'

export const TitleBarTitle = styled.h2`
  position: relative;
  top: -1px;
  margin-right: 4px;
  font-size: 24px;
  font-weight: normal;
  color: ${colors.teak};

  ${componentTheme('TitleBarTitle')}
`

TitleBarTitle.displayName = 'TitleBarTitle'
