import styled from 'styled-components'

import {componentTheme} from '../_utils'
import {colors} from '../theme'

export const Module = styled.div`
  margin-bottom: 20px;
  padding: 10px 14px 12px;
  border-radius: 4px;
  background-color: ${colors.white};
  box-shadow: ${props => props.theme.defaultStyles.boxShadow.base};

  ${componentTheme('Module')}
`

Module.displayName = 'Module'
