import styled from 'styled-components'

import {componentTheme} from '../_utils'
import {colors} from '../theme'

export const TitleBarSubtitle = styled.h3`
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 18px;
  line-height: 21px;
  font-weight: normal;
  color: ${colors.granitegreen};

  ${componentTheme('TitleBarSubtitle')}
`

TitleBarSubtitle.displayName = 'TitleBarSubtitle'
