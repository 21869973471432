import {getSourceDefinition} from 'shared/getSourceDefinition'
import {ILocation} from 'shared/types'

export const convertToTenths = (n, pow) => (n / pow).toFixed(n % pow === 0 ? 0 : 1)

export const isState = (city: string | null, state: string): boolean => {
  return city === state || city === null
}

export const dateFormats = {
  long: 'h:mm a, MM-DD-YYYY',
  short: 'MM-DD-YYYY',
}

export const formatLocation = (location: ILocation): string => {
  const {city, state, stateAbbreviation} = location
  return isState(city, state) ? state : `${city}, ${stateAbbreviation}`
}

export const formatSource = (source: string, sources): string => {
  return getSourceDefinition(source, sources).displayName
}

export const formatUrlWithoutProtocol = (url: string): string => {
  return url.replace(/https?:\/\//, '')
}

export const makeOxfordList = (a: string[], terminalJoin: 'and' | 'or'): string => {
  if (a.length < 2) {
    return a.join('')
  }
  if (a.length === 2) {
    return a.join(` ${terminalJoin} `)
  }
  return `${a.slice(0, -1).join(', ')}, ${terminalJoin} ${a[a.length - 1]}`.trim()
}

export const pluralize = (n: number | undefined, singularTerm: string, pluralTerm: string) => {
  if (n === 1) {
    return singularTerm
  }
  return pluralTerm
}

/**
 * Scrambles the text by replacing each character with another through a process
 * of shifting each character over by 5 in the alphaArray
 * @param adText - string to scramble
 */
export const scrambleWords = (adText: string) => {
  const alphaArray = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
  ]
  return adText.replace(/[a-z]/gi, char => {
    const index = alphaArray.indexOf(char.toLowerCase())
    const shift = 5
    const shiftedIndex = (index + shift) % alphaArray.length
    return alphaArray[shiftedIndex]
  })
}

export const simplifyNumber = (n?: number): string => {
  if (n === undefined) {
    return ''
  }
  if (n > 999999999) {
    return `${convertToTenths(n, 1000000000)}B`
  }
  if (n > 999999) {
    return `${convertToTenths(n, 1000000)}M`
  }
  if (n > 999) {
    return `${convertToTenths(n, 1000)}K`
  }
  return n.toString()
}

export const calculateFileSize = (bytes: number, floatLength: number = 0) => {
  if (!bytes) {
    return '0B'
  }
  const exp = Math.floor(Math.log(bytes) / Math.log(1024))
  const size = (bytes / 1024 ** exp).toFixed(floatLength)
  const unit = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'][exp]

  // the addtional parseFloat() will remove trailing zeroes (ex: 58.0mb => 58mb)
  return `${parseFloat(size)}${unit}`
}

export const capitalizeString = str => str.charAt(0).toUpperCase() + str.slice(1)

export const metersToMiles = (meters: number) => Math.floor(meters * 0.00062137)
