// Organized by grayscale-to-color, then ROY G BIV
// Color names are based off of their crayola equivalent: http://chir.ag/projects/name-that-color

// Grays - sorted lightest to darkest
export const purewhite = '#ffffff' // the lightest color, we will rarely if ever need to use this
export const white = '#fdfdfd' // extremely light gray, use this instead of #fff in most cases
export const lightpampas = '#faf9f7'
export const pampas = '#f1eee7'
export const satinlinen = '#e6e1da' // light sandy warm gray
export const alto = '#d8d8d8'
export const timberwolf = '#d6d2cb'
export const cottonseed = '#c3bfb9'
export const cloudy = '#b3afaa'
export const silverchalice = '#a9a9a9'
export const grayolive = '#a8a294' // medium warm gray
export const dawn = '#a8a5a0' // medium
export const granitegreen = '#837b6d' // dark warm gray
export const ironside = '#62615e' // very dark warm gray

// Reds
export const wepeep = '#f7e1df' // light pink
export const bizarre = '#edd8d5' // desaturated light pink
export const tonyspink = '#e68c81' // medium pink
export const chestnutrose = '#cd7064' // dark pink
export const hippiepink = '#a6415d' // maroon

// Oranges
export const anzac = '#deb135' // medium orange
export const copper = '#c2803a' // dark orange

// Yellows
export const linen = '#fcf9ed' // very light off-white
export const marzipan = '#fae29f' // medium yellowish-orange
export const alpine = '#b5902b' // yellow-brown
export const teak = '#af9968' // light gold
export const leather = '#937e58' // dark gold
export const luxor = '#a18128' // dark yellow-brown
export const kumera = '#87691c' // very dark yellow-brown

// Greens
export const junglemist = '#bfd9db' // light desaturated green
export const gumbo = '#72a2a7' // bright teal
export const patina = '#609499' // dark teal
export const stack = '#869485' // medium desaturated green

// Blues
export const mystic = '#ebf0f2' // extremely light pale blue
export const geyser = '#dae2e6' // very light blue-gray
export const iron = '#cfd6d8' // lighter blue-gray
export const loblolly = '#becbd1' // light blue-gray
export const casper = '#b8cbd9' // light blue
export const hitgray = '#a7b0b3' // blue-gray
export const oslogray = '#979c9e' // medium blue gray
export const calypso = '#377c91' // medium bright blue
export const jellybean = '#2b6c9e' // dark blue (links)
export const rollingstone = '#727d82' // dark blue gray
export const shuttlegray = '#5f6877' // dark bright blue
export const riverbed = '#464d59' // dark blue (sidebar)

// Violets
export const waikawagray = '#5e66a0' // dark purplish
