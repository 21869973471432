import * as React from 'react'
import styled, {css} from 'styled-components'
import {StyledIconProps} from 'styled-icons/types'

import * as supportedIcons from 'shared/supportedIcons'
import {IconName} from 'shared/types'

import {componentTheme} from '../_utils'
import {colors} from '../theme'

export interface Props extends StyledIconProps {
  name: IconName
  // tslint:react-unused-props-and-state
  // eslint-disable-next-line
  children?: never
  className?: string
}

const iconCSS = css`
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  font-size: 20px;
  color: ${colors.cloudy};
  user-select: none;

  ${componentTheme('Icon')}
`

const ExifIcon = styled.span`
  ${iconCSS} width: initial;
  height: initial;
  padding: 0 2px;
  border: 1px solid ${colors.cloudy};
  border-radius: 2px;
  font-size: 8px;
  font-weight: bold;
  text-transform: uppercase;
`

const StyledSupportedIcons = Object.keys(supportedIcons).reduce(
  (styledIcons, name) => {
    styledIcons[name] = styled(supportedIcons[name])`
      ${iconCSS};
      color: ${colors.cloudy};
    `
    return styledIcons
  },
  {exif: ExifIcon},
) as {[name in IconName]: any}

export const getIcon = (name: IconName) => StyledSupportedIcons[name]

const kebabCase = (str: string) => {
  return str
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/[^a-zA-Z]+/g, '-')
    .toLowerCase()
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Icon: React.SFC<Props> = ({name, className = '', ref, ...other}) => {
  switch (name) {
    case 'exif':
      return (
        <ExifIcon className={`icons ${kebabCase(name)} ${className}`} {...(other as any)}>
          {name}
        </ExifIcon>
      )
    default:
      if (!supportedIcons[name]) {
        throw new Error(`Could not find icon ${name} - please add to supportedIcons.ts`)
      }
      const StyledIcon = getIcon(name)
      return <StyledIcon className={`icons ${kebabCase(name)} ${className}`} {...other} />
  }
}
