import styled from 'styled-components'

import {componentTheme} from '../_utils'
import {Module} from '../Module'

export const TitleBar = styled(Module)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 9px;

  ${componentTheme('TitleBar')}
`

TitleBar.displayName = 'TitleBar'
