import {IconName, IFilterCategory, IFilterCheckboxValue, IFilterRangeValue, IFilterValue} from './types'

export const FILTER_CATEGORIES: {[category: string]: IFilterCategory} = {
  ageRange: {
    icon: 'Face',
    pageTitleNegatedPrefix: 'without ages',
    pageTitlePrefix: 'with ages',
    type: 'range',
    pluralName: 'Age Range',
  },
  dateRange: {
    icon: 'Today',
    pageTitleNegatedPrefix: 'without dates',
    pageTitlePrefix: 'with dates',
    type: 'range',
    pluralName: 'Date Range',
  },
  locations: {
    icon: 'Place',
    pageTitleNegatedPrefix: 'not located in',
    pageTitlePrefix: 'located in',
    type: 'checkbox',
    pluralName: 'Locations',
    hasSearch: true,
  },
  sources: {
    icon: 'Globe',
    pageTitleNegatedPrefix: 'not posted on',
    pageTitlePrefix: 'posted on',
    type: 'checkbox',
    pluralName: 'Sources',
  },
  phones: {
    icon: 'Phone',
    pageTitleNegatedPrefix: 'without a phone number of',
    pageTitlePrefix: 'with a phone number of',
    type: 'checkbox',
    pluralName: 'Phone Numbers',
    hasSearch: true,
  },
  emails: {
    icon: 'Email',
    pageTitleNegatedPrefix: 'without an email address of',
    pageTitlePrefix: 'with an email address of',
    type: 'checkbox',
    pluralName: 'Email Addresses',
    hasSearch: true,
  },
  aliases: {
    icon: 'Person',
    pageTitleNegatedPrefix: 'without an alias of',
    pageTitlePrefix: 'with an alias of',
    type: 'checkbox',
    pluralName: 'Aliases',
    hasSearch: true,
  },
  tags: {
    icon: 'LocalOffer',
    pageTitleNegatedPrefix: 'not tagged as',
    pageTitlePrefix: 'tagged as',
    type: 'checkbox',
    pluralName: 'Tags',
  },
  // These aren't displayed in the client, so they don't need every field
  profileIds: {
    icon: '' as IconName,
    pageTitleNegatedPrefix: '',
    pageTitlePrefix: '',
    type: 'checkbox',
    pluralName: '',
  },
}

export const getFilterCategory = (category: string): IFilterCategory => {
  return FILTER_CATEGORIES[category]
}

export const isCategoryType = (category: string, type: string): boolean => {
  return FILTER_CATEGORIES[category].type === type
}

export const isCheckboxFilter = (filter: IFilterValue): filter is IFilterCheckboxValue => {
  return FILTER_CATEGORIES[filter.category].type === 'checkbox'
}

export const isRangeFilter = (filter: IFilterValue): filter is IFilterRangeValue => {
  return FILTER_CATEGORIES[filter.category].type === 'range'
}

export const findCheckboxFilters = (filters: IFilterValue[]): IFilterCheckboxValue[] => {
  const checkboxFilters: IFilterCheckboxValue[] = []

  for (const filter of filters) {
    if (isCheckboxFilter(filter)) {
      checkboxFilters.push(filter)
    }
  }

  return checkboxFilters
}

export const findCheckboxFiltersWithCategory = (
  filters: IFilterValue[] | undefined,
  category: string,
): IFilterCheckboxValue[] => {
  return findCheckboxFilters(filters || []).filter(f => f.category === category)
}
