import { Card as BaseCard, CardOverrides, CardProps } from 'baseui/card';
import React, { FC } from 'react';

const getOverrides = (className: string): CardOverrides => {
  return {
    Root: {
      style: {
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
        borderBottomLeftRadius: '6px',
        borderBottomRightRadius: '6px',
        borderTopStyle: 'none',
        borderRightStyle: 'none',
        borderBottomStyle: 'none',
        borderLeftStyle: 'none',
        borderTopWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        borderLeftWidth: 0,

        boxShadow:
          '0 3px 3px -2px rgba(0,0,0,0.2), 0 3px 4px 0 rgba(0,0,0,0.14), 0 1px 8px 0 rgba(0,0,0,0.12)',
        paddingLeft: '16px',
        paddingRight: '16px',
        paddingTop: '8px',
        paddingBottom: '8px',
      },
      props: {
        className,
      },
    },
  };
};

interface Props extends CardProps {
  className?: string;
}

const Card: FC<Props> = (props) => {
  const { className = '', ...baseProps } = props;

  const overrides = getOverrides(className);

  return <BaseCard overrides={overrides} {...baseProps} />;
};
export default Card;
