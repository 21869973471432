import React, { FC, ReactChild } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  height: 150px;
  width: 350px;

  text-align: center;
`;

const IconBackground = styled.div`
  border-radius: 50%;
  background-color: rgba(235, 240, 242, 0.5);
  height: 50px;
  width: 50px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
`;

const Header = styled.h2`
  color: #464d5a;
  font-family: Helvetica Neue;
  font-size: 12px;
  font-weight: normal;
  margin-bottom: 20px;
`;

const Message = styled.div`
  color: #454e5a;
  font-family: Helvetica Neue;
  font-size: 16px;
`;

export interface Props {
  icon: ReactChild;
  header: string;
  message: string;
  className?: string;
}

const Result: FC<Props> = ({ icon, header, message, className = '' }) => {
  return (
    <Container className={className}>
      <IconBackground>{icon}</IconBackground>
      <Header>{header}</Header>
      <Message>{message}</Message>
    </Container>
  );
};
export default Result;
