import {Security} from '@okta/okta-react'
import React, {useCallback} from 'react'

import {oktaAuth} from 'lib/authenticate'
import browserHistory from 'lib/browserHistory'

export const SecurityProvider: React.FC = ({children}) => {
  const onAuthRequired = useCallback(() => {
    browserHistory.push('/login')
  }, [])

  const restoreOriginalUri = useCallback(async (_oktaAuth, originalUri) => {
    // If the user did not visit a particular page, we will redirect them to the dashboard.
    browserHistory.push(originalUri || '/')
  }, [])

  return (
    <Security oktaAuth={oktaAuth} onAuthRequired={onAuthRequired} restoreOriginalUri={restoreOriginalUri}>
      {children}
    </Security>
  )
}
