import queryString from 'query-string'
import React, {MutableRefObject, useContext, useEffect, useRef, useState} from 'react'

import browserHistory from 'lib/browserHistory'
import {buildPageTitleForFilters} from 'lib/filters'
import {makeOxfordList} from 'lib/formats'
import {scrollToTopOfPage} from 'lib/scrollToTopOfPage'
import thaxios, {cancelRequests, getCancelToken} from 'lib/thaxios'
import {IImageSearch, ISearchOptions, ISearchProfileTotals} from 'shared/types'

import {emitError} from '../ErrorListener'

import {AppContext} from './AppContext'
import {AuthenticationContext} from './AuthenticationContext'
import {FiltersProvider} from './FiltersContext'

export interface ISearchContext {
  imageSearchMediaUrl?: string
  isInstantiatingSearchId: boolean
  isDefaultLocationsChecked: boolean | undefined
  setIsDefaultLocationsChecked(value: boolean): void
  searchId?: string
  searchBarInputRef: MutableRefObject<HTMLInputElement | null>
  searchOptions?: ISearchOptions
  searchPageTitleSuffix: string
  searchTotals: ISearchProfileTotals
  setSearchTotals(searchTotals: ISearchProfileTotals): void
  triggerImageSearch(imageSearch: IImageSearch): Promise<void>
  triggerNewSearch(updatedSearchOptions: ISearchOptions, isDefaultSearch?: boolean): Promise<void>
  triggerRedirectSearch(searchOptions: ISearchOptions, redirectPathname: string)
  clearSearches?: () => void
}

export interface ISearchProviderProps {
  searchIdKey: string
  defaultSearchOptions?: ISearchOptions
}

interface IActionDefinition {
  isUserUploaded: boolean | undefined
  md5: string
  name: string | undefined
  s3Key: string
}

const buildPageTitle = searchOptions => {
  const titleParts: string[] = []

  if (searchOptions) {
    const query = searchOptions.query
    const filters = searchOptions.filters

    if (query) {
      titleParts.push(`containing "${query}"`)
    }

    if (filters && filters.length) {
      titleParts.push(buildPageTitleForFilters(filters))
    }
  }

  return makeOxfordList(titleParts, 'and')
}

// defaultSearchId should always be DEFAULT_ID
// if searchOptions are applied, searchId should be updated
// if searchOptions is empty/undefined, searchId should be set to defaultSearchId

export const SearchContext = React.createContext<ISearchContext>({} as ISearchContext)
export const SearchProvider: React.FC<ISearchProviderProps> = props => {
  const {user} = useContext(AuthenticationContext)
  const {userSettings} = useContext(AppContext)
  const [isInstantiatingSearchId, setIsInstantiatingSearchId] = useState(true)
  const [isDefaultLocationsChecked, setIsDefaultLocationsChecked] = useState<boolean | undefined>(undefined)
  const [searchOptions, setSearchOptions] = useState<ISearchOptions>(props.defaultSearchOptions || {})
  const imageSearch = searchOptions && searchOptions.imageSearch
  const [imageSearchMediaUrl, setImageSearchMediaUrl] = useState<string | undefined>(undefined)
  const searchBarInputRef = useRef<HTMLInputElement | null>(null)
  const [searchTotals, setSearchTotals] = useState<ISearchProfileTotals | undefined>(undefined)

  // TODO find a simpler pattern for this logic
  const [defaultSearchId, setDefaultSearchId] = useState(props.defaultSearchOptions ? undefined : 'DEFAULT_ID')
  const parsedQueryString = queryString.parse(browserHistory.location.search)
  const qsSearchId = parsedQueryString[props.searchIdKey]
  const searchId = qsSearchId ? qsSearchId.toString() : defaultSearchId

  const [actionDefinition, setActionDefinition] = useState<IActionDefinition | null>(null)
  const linkAction = parsedQueryString.action

  // If we're loading a page that has `?action=value` query parameter, then
  // we reset some of the the search context state in order to instantiate correctly.
  useEffect(() => {
    if (linkAction) {
      setIsInstantiatingSearchId(true)
      setIsDefaultLocationsChecked(undefined)
      setActionDefinition({
        isUserUploaded: Boolean(parsedQueryString.isUserUploaded) || undefined,
        md5: String(parsedQueryString.md5 || ''),
        name: String(parsedQueryString.name || '') || undefined,
        s3Key: String(parsedQueryString.s3Key || ''),
      })

      // remove the 'action' params from the URL
      browserHistory.replace({
        ...browserHistory.location,
        search: queryString.stringify({
          ...parsedQueryString,
          action: undefined,
          isUserUploaded: undefined,
          md5: undefined,
          name: undefined,
          s3Key: undefined,
        }),
      })
    }
  }, [linkAction, parsedQueryString])

  // POST a new search getting the searchId back
  const fetchSearchId = async (
    newSearchOptions: ISearchOptions,
    isDefaultSearch?: boolean,
    redirectPathname?: string,
  ) => {
    scrollToTopOfPage()
    // cancel old requests
    if (searchId) {
      cancelRequests(searchId, 'Executing a new search request')
    }

    performance.mark('startSearch')
    const searchData = await thaxios.post('/searches', {
      data: {
        parameters: newSearchOptions,
      },
    })

    if (isDefaultSearch) {
      // set this before setting the 'searchId' in order to prevent updating the URL
      setDefaultSearchId(searchData.searchId)
      setIsInstantiatingSearchId(false)
    } else {
      // update permalink if the searchId has any user-applied filters
      const historyOptions: any = {
        pathname: redirectPathname || browserHistory.location.pathname,
      }
      const redirectKeys = {'/search': 'id', '/profiles': 'searchId'}
      const searchKey = redirectPathname ? redirectKeys[redirectPathname] : props.searchIdKey
      if (searchData.searchId && searchData.searchId !== defaultSearchId) {
        const isChangingPages = redirectPathname && redirectPathname !== browserHistory.location.pathname
        historyOptions.search = queryString.stringify({
          ...(isChangingPages ? {} : parsedQueryString), // clear query params for new pages
          [searchKey]: searchData.searchId,
        })
      }
      setIsInstantiatingSearchId(false)

      if (isInstantiatingSearchId) {
        browserHistory.replace(historyOptions)
      } else {
        browserHistory.push(historyOptions)
      }
    }
  }

  const triggerImageSearch = async (newImageSearch: IImageSearch) => {
    // clear the previous image preview
    setImageSearchMediaUrl(undefined)
    await fetchSearchId(
      {
        bulkSearchPhones: undefined,
        imageSearch: newImageSearch,
        query: '',
      },
      false,
      '/search',
    )
  }

  const triggerNewSearch = async (updatedSearchOptions: ISearchOptions, isDefaultSearch?: boolean) => {
    const latestUserQuery = searchBarInputRef.current ? searchBarInputRef.current.value : searchOptions.query
    const newSearchOptions = {
      ...searchOptions,
      query: latestUserQuery,
      ...updatedSearchOptions,
    }
    await fetchSearchId(newSearchOptions, isDefaultSearch)
  }

  const triggerRedirectSearch = async (redirectSearchOptions: ISearchOptions, redirectPathname: string) => {
    await fetchSearchId(redirectSearchOptions, false, redirectPathname)
  }

  // During instantiation, determine if we need to kick off a new search or if we should use the
  // pre-existing 'searchId' from the URL. There are three cases where this happens:
  //
  // 1. On profile pages, we initialize a hidden 'searchId' with a filter for a specific Profile ID
  // 2. On search pages, we initialize an image search using query parameters from the URL
  // 3. On search pages, we initialize a search with the user's "default locations" filters
  //
  useEffect(() => {
    // wait for the link action to be resolved before continuing
    if (!isInstantiatingSearchId || linkAction) {
      return
    }

    const {md5, s3Key, name, isUserUploaded} = actionDefinition || {}

    if (props.defaultSearchOptions && !searchId) {
      triggerNewSearch(props.defaultSearchOptions, true).catch(emitError)
    } else if (md5 && s3Key) {
      triggerImageSearch({md5, s3Key, name, isUserUploaded}).catch(emitError)
    } else if (
      searchId === defaultSearchId &&
      user.defaultLocations.length &&
      userSettings.isDefaultLocationsAutomatic
    ) {
      fetchSearchId({filters: user.defaultLocations}).catch(emitError)
      setIsDefaultLocationsChecked(true)
    } else {
      performance.mark('startSearch')
      setIsInstantiatingSearchId(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInstantiatingSearchId, linkAction])

  // GET SearchOptions for a given searchId
  useEffect(() => {
    ;(async () => {
      if (!searchId) {
        return
      }
      const searchData = await thaxios.get(`/searches/${searchId}`, {
        cancelToken: getCancelToken(searchId),
      })
      setSearchOptions(searchData)
    })().catch(emitError)

    // Whenever searchId changes, cancel any inflight requests
    return () => {
      if (searchId) {
        cancelRequests(searchId, 'searchId changed')
      }
    }
  }, [searchId])

  const clearSearches = async () => {
    if (!searchId) {
      return
    }

    const profileIdFilters = searchOptions.filters?.filter(f => f.category === 'profileIds')

    const clearedOptions = {
      ...searchOptions,
      filters: profileIdFilters,
    }

    await triggerNewSearch(clearedOptions)
  }

  // fetch the url of the imageSearch media
  useEffect(() => {
    ;(async () => {
      if (!imageSearch) {
        return
      }

      const url = await thaxios.post(`/media/url`, {data: imageSearch})
      setImageSearchMediaUrl(url)
    })().catch(emitError)
  }, [imageSearch])

  return (
    <SearchContext.Provider
      value={{
        imageSearchMediaUrl,
        isInstantiatingSearchId,
        isDefaultLocationsChecked,
        setIsDefaultLocationsChecked,
        searchId,
        searchBarInputRef,
        searchOptions,
        searchTotals: searchTotals || {totalAds: 0, totalProfiles: 0},
        setSearchTotals,
        searchPageTitleSuffix: buildPageTitle(searchOptions),
        triggerImageSearch,
        triggerNewSearch,
        triggerRedirectSearch,
        clearSearches,
      }}
    >
      <FiltersProvider searchOptions={searchOptions} onFiltersUpdateEvent={filters => triggerNewSearch({filters})}>
        {props.children}
      </FiltersProvider>
    </SearchContext.Provider>
  )
}
