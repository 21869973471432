/* eslint-disable */

// required to use https://baseweb.design/components/snackbar
import ResizeObserver from 'resize-observer-polyfill'

// @ts-ignore https://github.com/Microsoft/TypeScript/issues/28502
if (!window.ResizeObserver) {
  // @ts-ignore
  window.ResizeObserver = ResizeObserver
}
// Polyfill for ChildNode.remove()
// https://developer.mozilla.org/en-US/docs/Web/API/ChildNode/remove
//
// from:https://github.com/jserz/js_piece/blob/master/DOM/ChildNode/remove()/remove().md
;(arr => {
  arr.forEach(item => {
    if (item.hasOwnProperty('remove')) {
      return
    }
    Object.defineProperty(item, 'remove', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function remove() {
        this.parentNode.removeChild(this)
      },
    })
  })
})([Element.prototype, CharacterData.prototype, DocumentType.prototype])

// via https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/repeat#Polyfill
if (!String.prototype.repeat) {
  String.prototype.repeat = function (count) {
    'use strict'
    if (this == null) throw new TypeError("can't convert " + this + ' to object')

    var str = '' + this
    // To convert string to integer.
    count = +count
    // Check NaN
    if (count != count) count = 0

    if (count < 0) throw new RangeError('repeat count must be non-negative')

    if (count == Infinity) throw new RangeError('repeat count must be less than infinity')

    count = Math.floor(count)
    if (str.length == 0 || count == 0) return ''

    // Ensuring count is a 31-bit integer allows us to heavily optimize the
    // main part. But anyway, most current (August 2014) browsers can't handle
    // strings 1 << 28 chars or longer, so:
    if (str.length * count >= 1 << 28) throw new RangeError('repeat count must not overflow maximum string size')

    var maxCount = str.length * count
    count = Math.floor(Math.log(count) / Math.log(2))
    while (count) {
      str += str
      count--
    }
    str += str.substring(0, maxCount - str.length)
    return str
  }
}

// via https://github.com/Financial-Times/polyfill-service/blob/556b52d8a554fec6f96a32eee4a83aa3d54c1d40/polyfills/Node.prototype.contains/polyfill-HTMLElement.js
if (!Node.prototype.contains) {
  Node.prototype.contains = function contains(node) {
    if (!(0 in arguments)) {
      throw new TypeError('1 argument is required')
    }

    do {
      if (this === node) {
        return true
      }
    } while ((node = node && node.parentNode))

    return false
  }
}

export {}
