import { Grid as BaseGrid, GridProps } from 'baseui/layout-grid';
import React, { FC } from 'react';

const Grid: FC<GridProps> = props => {
  const { children } = props;
  return (
    <BaseGrid gridGutters={[0, 0, 0]} gridMargins={[0, 0, 0]} {...props}>
      {children}
    </BaseGrid>
  );
};
export default Grid;
