import config from '../config'

export const url = `${config.apiUrl}/api`
export const issuer = config.okta.issuerUrl
export const authUrl = config.okta.baseUrl
export const clientId = config.clientId
const API = {
  url,
  issuer,
  authUrl,
  clientId,
}

export default API
