import {createTracker} from './core'

//
// ** WARNING **
//
// Any event added to this list will be visible to any unauthenticated visitor to Spotlight.
// Do not include any events in here that are specific to our confidential functionality.
// Instead, add confidential events to authedTracking.ts

const unauthedEvents = {
  authAccessSubmitForm: {
    category: 'Authentication',
    action: 'Request Access: Submit Form',
  },
  authAccessSubmitFail: {
    category: 'Authentication',
    action: 'Request Access: Submission Fails',
  },
  authAccessAgencyInvalid: {
    category: 'Authentication',
    action: 'Request Access: Agency ID invalid',
  },
  // authAccessAgencyHelperText: {
  //   category: 'Authentication',
  //   action: 'Request Access: Agency ID What is this?',
  // },
  authAccessNoAgencyID: {
    category: 'Authentication',
    action: `Request Access: Agency ID I don't have an agency ID`,
  },
  // authAccessApprovalHelperText: {
  //   category: 'Authentication',
  //   action: 'Request Access: Approval Letter What is this?',
  // },
  authAccessApprovalUpload: {
    category: 'Authentication',
    action: 'Request Access: Select a file approval letter',
  },
  authAccessFooterLogin: {
    category: 'Authentication',
    action: 'Request Access: Login footer link',
  },
  authAccessLocation: {
    category: 'Authentication',
    action: 'Request Access: Location autocomplete',
  },
  errorPageLoaded: {
    category: 'Performance',
    action: 'Error Page - Loaded',
  },
}

const track = createTracker<typeof unauthedEvents>(unauthedEvents)

export {track, unauthedEvents}
