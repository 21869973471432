import {numberToPx} from './numberToPx'
import {palette} from './styledTools'

export type Position = 'top' | 'right' | 'bottom' | 'left'

export type PropsFn = (props: {[key: string]: any}) => any

export const bool = (cssProp: string, validComponentProps: string[]): PropsFn => props => {
  const keys = Object.keys(props)
    .filter(k => props[k] && validComponentProps.indexOf(k) >= 0)
    .map(k => k.replace(/([A-Z])/g, '-$1').toLowerCase())
  if (keys.length) {
    return `${cssProp}: ${keys.join(' ')};`
  }
  return ''
}

export const value = (cssProp: string, componentProp: string): PropsFn => props => {
  const v = props[componentProp]

  if (typeof v === 'undefined') {
    return ''
  }

  return `${cssProp}: ${numberToPx(v)};`
}

export const textColorWithProps = props =>
  palette(props.opaque ? `${props.palette}Text` : props.palette, props.tone || 0, 'inherit')

export const bgColorWithProps = props => {
  if (!props.opaque) {
    return 'unset'
  }
  return palette(props.palette, props.tone || 0, 'unset')
}

interface TransitionProps {
  animated?: boolean
  fade?: boolean | string
  slide?: boolean | string
  expand?: boolean | string
}

export const hasTransition = (props: TransitionProps) =>
  Boolean(props.animated || props.fade || props.slide || props.expand)

export const translate3d = (x?: string | number, y?: string | number, z?: string | number) =>
  `translate3d(${numberToPx(x)}, ${numberToPx(y)}, ${numberToPx(z)})`

export const origin = (x: string | number = 'center', y: string | number = 'center') =>
  `${numberToPx(x)} ${numberToPx(y)}`

export const calc = (a?: string | number, b?: string | number) => `calc(${numberToPx(a)} + ${numberToPx(b)})`

export const minus = (v?: string | number) => `-${numberToPx(v)}`

export const expand = <P extends {expand?: boolean | 'center' | Position; defaultExpand?: string}>(props: P) =>
  props.expand ? props.defaultExpand || 'center' : props.expand

export const slide = <P extends {slide?: string; defaultSlide?: string}>(props: P) =>
  props.slide ? props.defaultSlide || 'center' : props.slide

export const scaleWithProps = <P extends {expand?: boolean | 'center' | Position}>(props: P) =>
  props.expand ? 'scale(0.01)' : undefined

export const originWithProps = <
  P extends {
    expand?: boolean | 'center' | Position
    originX?: string
    originY?: string
  }
>(
  props: P,
) => {
  const x = props.originX
  const y = props.originY
  const values = {
    center: origin(calc('50%', x), calc('50%', y)),
    top: origin(calc('50%', x), calc('100%', y)),
    right: origin(x, calc('50%', y)),
    bottom: origin(calc('50%', x), y),
    left: origin(calc('100%', x), calc('50%', y)),
  }

  return values[expand(props) || 'none'] || origin(calc('50%', x), calc('50%', y))
}

export const translateWithProps = props => translate3d(props.translateX, props.translateY)

export const slideWithProps = props => {
  const x = props.translateX
  const y = props.translateY
  const offset = props.slideOffset || '100%'
  const values = {
    top: translate3d(x, calc(offset, y)),
    right: translate3d(calc(minus(offset), x), y),
    bottom: translate3d(x, calc(minus(offset), y)),
    left: translate3d(calc(offset, x), y),
  }

  return values[slide(props) || 'none'] || translate3d(x, y)
}
