import * as React from 'react'
import styled from 'styled-components'

import {bgColorWithProps, bool, componentTheme, Omit, pickHTMLProps, textColorWithProps} from '../_utils'

export type BoxProps<T = any> = Omit<React.PropsWithRef<React.HTMLProps<T>>, 'as'> & {
  static?: boolean
  absolute?: boolean
  fixed?: boolean
  relative?: boolean
  sticky?: boolean
  opaque?: boolean
  palette?: string
  tone?: number
  element?: keyof JSX.IntrinsicElements | React.ComponentType<any>
  elementRef?: React.Ref<T>
}

const BoxComponent = React.forwardRef<HTMLElement, BoxProps>(({element: T = 'div', ...props}, ref) => {
  const htmlProps = pickHTMLProps(props)

  return <T {...htmlProps} ref={ref} />
})

const positions = ['static', 'absolute', 'fixed', 'relative', 'sticky']

export const Box = styled(BoxComponent)`
  margin: unset;
  padding: unset;
  border: unset;
  background: unset;
  font: unset;
  font-family: inherit;
  font-size: 100%;
  box-sizing: border-box;
  background-color: ${bgColorWithProps};
  color: ${textColorWithProps};

  ${componentTheme('Box')}

  &&& {
    ${bool('position', positions)};
  }
`
