import axios from 'axios'
import React, {useEffect, useState} from 'react'

import {captureOriginalUrl} from 'lib/authenticate'
import browserHistory from 'lib/browserHistory'
import {tryParseErrorMessage, tryParseStatusCode} from 'lib/thaxios'

import config from './config'

type Listener = (error: Error) => void

class ErrorStream {
  listeners: Listener[] = []

  subscribe = (listener: Listener) => {
    this.listeners.push(listener)
  }

  unsubscribe = (listener: Listener) => {
    const index = this.listeners.indexOf(listener)
    this.listeners.splice(index, 1)
  }

  emit = (error: Error) => {
    for (const listener of this.listeners) {
      listener(error)
    }
  }
}

const ERROR_STREAM = new ErrorStream()

export const ErrorListener: React.FC = ({children}) => {
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    const onError = (err: Error) => {
      // Ignore canceled requests
      if (axios.isCancel(err)) {
        return
      }

      // Propogate any unexpected errors, but just
      // redirect for Unauthorized, Forbidden, and NotFound errors
      const statusCode = tryParseStatusCode(err)

      switch (statusCode) {
        case 401:
          if (browserHistory.location.pathname !== '/login' && browserHistory.location.pathname !== '/logout') {
            captureOriginalUrl()
            browserHistory.push('/logout')
          }
          break
        case 403:
          const message = tryParseErrorMessage(err)
          if (message && message.includes('invalid-eula')) {
            browserHistory.push('/eula')
          } else {
            browserHistory.push('/403')
          }
          break
        case 404:
          browserHistory.replace('/404')
          break
        default:
          setError(err)
      }
    }

    ERROR_STREAM.subscribe(onError)
    return () => {
      ERROR_STREAM.unsubscribe(onError)
    }
  })

  // Need to throw the error within the component lifecycle
  // in order for it to be caught by the error boundary.
  if (error) {
    if (config.bugsnag.releaseStage === 'development') {
      // eslint-disable-next-line no-console
      console.error(error)
    }
    throw error
  }

  return <>{children}</>
}

export const emitError = error => {
  ERROR_STREAM.emit(error)
}
