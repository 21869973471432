import React, { FC } from 'react';
import { Tag as BaseTag, TagProps, VARIANT } from 'baseui/tag';
import { ThemeColor, getColor } from '../../design/colors';

const getOverrides = (
  className?: string,
  textColor?: ThemeColor,
  bgColor?: ThemeColor,
  maxWidth?: string
) => {
  const overrides = {
    Root: {
      props: {
        className,
      },
      style: {
        paddingTop: '4px',
        paddingBottom: '4px',
        paddingLeft: '8px',
        paddingRight: '8px',
      },
    },
    Text: {
      style: {
        maxWidth,
      },
    },
  };

  if (textColor) {
    overrides.Root.style = {
      ...overrides.Root.style,
      /* @ts-ignore */
      color: getColor(textColor),
    };
  }

  if (bgColor) {
    overrides.Root.style = {
      ...overrides.Root.style,
      /* @ts-ignore */
      backgroundColor: getColor(bgColor),
    };
  }

  return overrides;
};

interface Props extends TagProps {
  className?: string;
  maxWidth?: string;
  bgColor?: ThemeColor;
  textColor?: ThemeColor;
}

const Tag: FC<Props> = props => {
  const {
    className,
    kind,
    closeable = false,
    variant = VARIANT.solid,
    textColor,
    bgColor,
    maxWidth,
    ...baseProps
  } = props;

  const overrides = getOverrides(className, textColor, bgColor, maxWidth);

  return (
    <BaseTag
      overrides={overrides}
      kind={kind}
      closeable={closeable}
      variant={variant}
      {...baseProps}
    ></BaseTag>
  );
};
export default Tag;
