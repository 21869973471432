import Bugsnag from '@bugsnag/js'
import {AuthState} from '@okta/okta-auth-js'
import {useOktaAuth} from '@okta/okta-react'
import retry from 'async-retry'
import {SnackbarProvider} from 'baseui/snackbar'
import React, {Suspense} from 'react'
import {createGlobalStyle} from 'styled-components'
import reset from 'styled-reset'

import {colors, ThemeInterface} from '@ui/theme'
import {Themed} from 'ui-library'

import {AppProvider} from './contexts/AppContext'
import {AuthenticationProvider} from './contexts/AuthenticationContext'
import {SecurityProvider} from './contexts/SecurityContext'
import {ErrorListener} from './ErrorListener'
import {initializeBugsnag} from './lib/bugsnag'
import {GenericErrorPage} from './pages/ErrorPage'
import {FullScreenLoading} from './pages/FullScreenLoadingPage'

initializeBugsnag()

const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary()

interface IGlobalStyle {
  theme: ThemeInterface
}

const GlobalStyle = createGlobalStyle<IGlobalStyle>`
  ${reset}

  body * {
    font-family: ${props => props.theme.typography.fontFamilies.primary};
    box-sizing: border-box;
    text-rendering: optimizeLegibility !important;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased !important;
  }

  a {
    color: ${colors.jellybean};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .tippy-tooltip.transparent-theme .enter {
    background: transparent !important;
  }
  .tippy-tooltip.dark-theme {
    background-color: ${colors.ironside};
    box-shadow: ${props => props.theme.defaultStyles.boxShadow.base};
    border: 1px solid ${colors.granitegreen};
    color: ${colors.lightpampas};
    opacity: 0.8;
  }
  .tippy-popper[x-placement^=top] [x-arrow] {
    border-top-color: ${colors.ironside};
  }
  .tippy-popper[x-placement^=bottom] [x-arrow] {
    border-bottom-color: ${colors.ironside};
  }
  // Workaround for removing tooltips from the DOM
  // See https://github.com/tvkhoa/react-tippy/issues/40#issuecomment-443568966
  .tippy-popper .leave {
    opacity: 0;
  }

  // IE11 will insert a native 'clear text' button
  // into inputs by default - this hides the button to prevent
  // have duplicate clear icons
  // see: https://github.com/twbs/bootstrap/issues/27623

  ::-ms-clear {
    display: none;
  }
`

const RETRY_OPTS = {
  retries: 4,
  minTimeout: 500,
  randomize: false,
}

// Log errors that occur during the import so that
// it's easier for engineers to detect what went wrong.
const logErrorAndThrow = err => {
  console.error(err) // eslint-disable-line no-console
  throw err
}

const AuthenticatedApp = React.lazy(() =>
  retry(() => import(/* webpackChunkName: "authed" */ './AuthenticatedApp').catch(logErrorAndThrow), RETRY_OPTS),
)
const UnauthenticatedApp = React.lazy(() =>
  retry(() => import('./UnauthenticatedApp').catch(logErrorAndThrow), RETRY_OPTS),
)

const SecurityConsumer: React.FC<{children: (isAuthenticated: AuthState | null) => React.ReactElement}> = ({
  children,
}) => {
  const {authState} = useOktaAuth()
  return children(authState)
}

const App: React.FC = () => {
  return (
    <AppProvider>
      <React.StrictMode>
        <GlobalStyle />
        <Themed>
          <ErrorBoundary FallbackComponent={GenericErrorPage}>
            <ErrorListener>
              <Suspense fallback={<FullScreenLoading />}>
                <SnackbarProvider
                  overrides={{
                    PlacementContainer: {
                      style: {
                        // above fixed header
                        zIndex: 2000,
                      },
                    },
                  }}
                >
                  <SecurityProvider>
                    <AuthenticationProvider>
                      <SecurityConsumer>
                        {authState => (authState?.isAuthenticated ? <AuthenticatedApp /> : <UnauthenticatedApp />)}
                      </SecurityConsumer>
                    </AuthenticationProvider>
                  </SecurityProvider>
                </SnackbarProvider>
              </Suspense>
            </ErrorListener>
          </ErrorBoundary>
        </Themed>
      </React.StrictMode>
    </AppProvider>
  )
}

export default App
