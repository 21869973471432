import * as React from 'react'
import styled from 'styled-components'

import {componentTheme} from '../_utils'
import {Icon} from '../Icon'
import {colors} from '../theme'

interface Props {
  checked?: boolean

  /** icon to display for the checked state */
  checkedIcon?: React.ReactNode

  /** CSS class names to attach */
  className?: string

  /** disable the checkbox */
  disabled?: boolean

  /** icon to display in the unchecked state */
  icon?: React.ReactNode

  /** text label */
  label?: React.ReactNode

  /** left or right */
  labelPosition?: string

  /** callback function for when the checkbox changes state */
  onCheck?: (value: boolean) => void

  /** text title */
  title?: string

  /** the value */
  value: string
}

interface State {
  checked: boolean
}

const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  &.left-label {
    order: -1;
  }
  position: relative;

  ${componentTheme('Checkbox')}
`
const HiddenCheckbox = styled.input`
  display: none;
`
const CheckboxIcon = styled(Icon)`
  margin-right: 6px;
  &.left-label {
    margin: 0 0 0 6px;
  }
  color: ${colors.timberwolf};
  cursor: pointer;
`
const CheckboxLabel = styled.div`
  &.left-label {
    order: -1;
  }
`

export class Checkbox extends React.Component<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      checked: false,
    }

    this.handleChange = this.handleChange.bind(this)
  }

  static defaultProps: any = {
    className: '',
    disabled: false,
    onCheck: () => null,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.checked !== nextProps.checked) {
      return {
        checked: nextProps.checked,
      }
    }
    return null
  }

  handleChange() {
    const {onCheck} = this.props
    const checked = !this.state.checked

    this.setState({checked})
    if (typeof onCheck === 'function') {
      onCheck(checked)
    }
  }

  renderCheckbox() {
    const {checkedIcon, icon, labelPosition} = this.props
    const labelClass = labelPosition === 'left' ? 'left-label' : ''
    if (this.state.checked) {
      return checkedIcon || <CheckboxIcon className={labelClass} name="CheckBox" />
    }
    return icon || <CheckboxIcon className={labelClass} name="CheckBoxOutlineBlank" />
  }

  render() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {className, disabled, label, labelPosition, title, value, onCheck, ...other} = this.props

    const labelClass = labelPosition === 'left' ? 'left-label' : ''

    return (
      <CheckboxContainer aria-label={title} className={`checkbox-container ${labelClass} ${className}`} {...other}>
        {/* hidden html checkbox */}
        <HiddenCheckbox
          aria-checked={this.state.checked}
          checked={this.state.checked}
          className="checkbox plain-element-button"
          disabled={disabled}
          onChange={this.handleChange}
          type="checkbox"
          value={value}
        />

        {/* stylized faux checkbox that controls the hidden checkbox */}
        {this.renderCheckbox()}

        {/* optional text label */}
        {label && <CheckboxLabel className={`checkbox-label ${labelClass}`}>{label}</CheckboxLabel>}
      </CheckboxContainer>
    )
  }
}
