import React, { FC } from 'react';
import { Heading as BaseHeading, HeadingProps } from 'baseui/heading';

type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6;

export interface Props extends HeadingProps {
  level: HeadingLevel;
}

const Heading: FC<Props> = props => {
  const { level, ...baseProps } = props;

  return <BaseHeading styleLevel={level} {...baseProps}></BaseHeading>;
};
export default Heading;
