import {createBrowserHistory, createLocation, LocationDescriptorObject} from 'history'

import {scrollToTopOfPage} from './scrollToTopOfPage'

const browserHistory = createBrowserHistory()

/**
 * There is a method in the History library called `locationsAreEqual`; unfortunately, this method
 * also checks the `location.key` attribute, which is a unique identifier generated whenever an
 * entry is added to the history, so it wouldn't help us at all. This purely checks the parts of the
 * location state that we would identify as indicating the same URL: `pathname`, `search`, and `hash`.
 */
const isSameLocation = (a: LocationDescriptorObject<any>, b: LocationDescriptorObject<any>): boolean => {
  return a.pathname === b.pathname && a.search === b.search && a.hash === b.hash
}

/**
 * History.push() does not prevent duplicate links from updating the browser history, so it's possible
 * to end up with an unusable back button in the application. This method checks to ensure that the
 * current location does not equal the passed in location prior to adding a new entry to the history.
 *
 * MJM 190808 I strongly dislike overriding the built-in `push` method in order to achieve this.
 * Originally, I simply handled the problem of introducing the `navigate` method by doing the following:
 *
 *     export default {
 *       {...browserHistory},
 *       navigate
 *     }
 *
 * And this worked great for direct calls to `browserHistory.navigate()`; however, `react-router` didn't
 * like this when dealing with `<Link to={...}/>` components. It would merely update the URL without
 * swapping out the components specified in the `Router`. In addition, `<Link/>` components could still
 * insert multiple duplicate records into the browser history, for instance, when clicking the "Spotlight"
 * logo multiple times from the search page.
 */
const {push} = browserHistory
browserHistory.push = (location: LocationDescriptorObject<any> | string) => {
  const newLocation: LocationDescriptorObject<any> = createLocation(location)
  if (browserHistory.location && isSameLocation(browserHistory.location, newLocation)) {
    console.debug('Same location navigation suppressed', browserHistory.location, newLocation)
  } else {
    push(newLocation)
  }
}

// scroll to the top of the page whenever we change locations
browserHistory.listen((_location, _action) => {
  scrollToTopOfPage()
})

export default browserHistory
