import Bugsnag from '@bugsnag/js'

import {IS_DEV, IS_PROD} from 'lib/environments'

import config from '../../config'
import browserHistory from '../browserHistory'

import {gtagEvent} from './gtags.client'

export interface Event {
  action: string
  category: string
  label?: string
  value?: number
}

type Events<T> = {[K in keyof T]: Event & {category?: string}}

export function createTracker<T>(events: Events<T>) {
  return (eventName: keyof T, data: Partial<Event> = {}) => {
    const event = events[eventName]
    const trackingData = {...event, ...data}

    if (trackingData && trackingData.action && trackingData.category) {
      if (IS_PROD) {
        gtagEvent(trackingData)
      } else if (IS_DEV) {
        console.info('tracking event', trackingData)
      }
    }
  }
}

const trackPage = (pathname: string) => {
  if (!window.gtag) {
    console.warn(
      'window.gtag is not defined. This could mean your google analytics script has not loaded on the page yet.',
    )

    Bugsnag.notify(`window.gtag not defined for trackPage`, event => {
      event.severity = 'warning'
    })

    return
  }

  window.gtag('config', config.googleAnalyticsId, {
    page_path: pathname,
  })
}

export const setGAUser = (userID: string, agencyID: string) => {
  if (!window.gtag) {
    console.warn(
      'window.gtag is not defined. This could mean your google analytics script has not loaded on the page yet.',
    )

    Bugsnag.notify(`window.gtag not defined for setGAUser`, event => {
      event.severity = 'warning'
    })
    return
  }

  window.gtag('set', {user_id: `${userID}:${agencyID}`})
}

browserHistory.listen(location => {
  trackPage(location.pathname)
})

// https://developers.google.com/analytics/devguides/collection/protocol/ga4/sending-events?client_type=firebase
if (window.PerformanceObserver && navigator.sendBeacon) {
  const observer = new PerformanceObserver(list => {
    list.getEntries().forEach(entry => {
      if (entry.name === 'firstAdLoaded') {
        const payload = {
          client_id: config.googleAnalyticsId,
          events: [
            {
              name: 'timing_complete',
              params: {
                name: 'First Meaningful Paint',
                value: Math.round(entry.duration),
                event_category: 'Performance',
                event_label: entry.name,
              },
            },
          ],
        }
        navigator.sendBeacon('https://www.google-analytics.com/g/collect', JSON.stringify(payload))
      }
    })
  })

  observer.observe({entryTypes: ['measure']})
}
