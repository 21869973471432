import React, { FC, HTMLAttributes, ReactChild } from 'react';

import { BaseProvider, createTheme, lightThemePrimitives } from 'baseui';
import { Borders, Colors, Theme, Typography } from 'baseui/theme';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { neutralColors, positiveColors, primaryColors } from './design/colors';

const engine = new Styletron();

const primitives = {
  ...lightThemePrimitives,
  primaryFontFamily: `'Helvetica Neue', sans-serif`,
};

interface ThemeOverrides {
  colors: Partial<Colors>;
  borders: Partial<Borders>;
  typography: Partial<Typography>;
}

const { primary1, primary2, primary3, primary4 } = primaryColors;
const { neutral1, neutral7 } = neutralColors;
const { positive6 } = positiveColors;

const defaultTheme = createTheme<ThemeOverrides>(primitives, {
  colors: {
    contentPrimary: neutral1,
    colorPrimary: neutral1,

    buttonPrimaryFill: primary4,
    buttonPrimaryHover: primary3,
    buttonSecondaryFill: '#FFF',
    buttonPrimaryActive: primary2,

    buttonSecondaryHover: '#D1EDF5', // todo: and 50% opacity

    tagPositiveSolidBackground: positive6,
    tagPositiveSolidFont: neutral1,

    tagAccentSolidBackground: '#E8C97D',
    tagAccentSolidFont: neutral1,

    tagNeutralSolidFont: neutral1,
    tagNeutralSolidBackground: neutral7,

    // radio button, checkbox
    tickFillSelected: primary4,
    tickFillHover: primary1,
    tickBorder: '#727D82',

    buttonSecondaryText: primary4,
  },
  borders: {
    buttonBorderRadius: '4px',
  },
  typography: {
    LabelMedium: {
      fontFamily: `'Helvetica Neue', sans-serif`,
      fontSize: '14px',
      fontWeight: 'normal',
      lineHeight: '1em',
    },
    LabelLarge: {
      fontFamily: `'Helvetica Neue', sans-serif`,
      fontSize: '16px',
      fontWeight: 'normal',
      lineHeight: '1em',
    },
    HeadingXXLarge: {
      fontFamily: `'Helvetica Neue', sans-serif`,
      fontSize: '36px',
      fontWeight: 'normal',
      lineHeight: '1em',
    },
    HeadingXLarge: {
      fontFamily: `'Helvetica Neue', sans-serif`,
      fontSize: '30px',
      fontWeight: 'normal',
      lineHeight: '1em',
    },
    HeadingLarge: {
      fontFamily: `'Helvetica Neue', sans-serif`,
      fontSize: '24px',
      fontWeight: 'normal',
      lineHeight: '1em',
    },
    HeadingMedium: {
      fontFamily: `'Helvetica Neue', sans-serif`,
      fontSize: '20px',
      fontWeight: 'normal',
      lineHeight: '1em',
    },
    HeadingSmall: {
      fontFamily: `'Helvetica Neue', sans-serif`,
      fontSize: '18px',
      fontWeight: 'normal',
      lineHeight: '1em',
    },
    HeadingXSmall: {
      fontFamily: `'Helvetica Neue', sans-serif`,
      fontSize: '16px',
      fontWeight: 'normal',
      lineHeight: '1em',
    },
  },
});

export interface Props extends HTMLAttributes<HTMLDivElement> {
  theme?: Theme;
  children?: ReactChild;
}

const Themed: FC<Props> = ({ theme = defaultTheme, children }) => {
  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={theme}>{children}</BaseProvider>
    </StyletronProvider>
  );
};
export default Themed;
