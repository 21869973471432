import React, { FC, ReactChild } from 'react';
import styled from 'styled-components';
import Skeleton from './Skeleton';

const GraphSkeletonGridContainer = styled.div<{ height: number }>`
  position: relative;
  height: ${props => props.height}px;
`;

const BarsContainer = styled.div`
  position: absolute;
  bottom: 0;
`;

const GraphSkeletonBars = styled.div<{ width: number; height: number }>`
  z-index: 10;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  transform: scaleY(-1);
`;

const SIZES = {
  SMALL: {
    width: 380,
    height: 190,
  },
};

type GraphSkeletonSize = 'small';

export interface Props {
  children?: ReactChild[];
  size?: GraphSkeletonSize;
}
const GraphSkeleton: FC<Props> = ({ size = 'small', children }) => {
  let width = SIZES.SMALL.width;
  let height = SIZES.SMALL.height;

  if (size !== 'small') {
    throw new Error('unknown GraphSkeleton size');
  }

  // determine optimal number of rows given graph height & row height/marginTop
  const ROW_HEIGHT = 38;
  const rows = Math.round(height / ROW_HEIGHT);

  return (
    <GraphSkeletonGridContainer height={height}>
      <Skeleton
        width={`${width}px`}
        rows={rows}
        overrides={{
          Row: {
            style: {
              height: '2px',
              marginTop: '36px',
              marginBottom: 0,
            },
          },
        }}
      />
      <BarsContainer>
        <GraphSkeletonBars width={width} height={height}>
          {children}
        </GraphSkeletonBars>
      </BarsContainer>
    </GraphSkeletonGridContainer>
  );
};

export default GraphSkeleton;
