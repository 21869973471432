import * as React from 'react'
import styled from 'styled-components'

import {colors} from '@ui/theme'

import SpotlightLogo from '../global/Logo/SpotlightLogo'

const PageContainer = styled.div`
  align-content: center;
  align-items: center;
  background: ${colors.riverbed};
  color: ${colors.pampas};
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
  min-height: 100vh;
  padding: 100px 0;
  width: 100%;
`

interface Props {
  hideLogo?: boolean
}

const AuthLayout: React.FC<Props> = ({children, hideLogo}) => (
  <PageContainer>
    {!hideLogo && <SpotlightLogo />}
    {children}
  </PageContainer>
)

export default AuthLayout
