import { BlockOverrides } from 'baseui/block';
import { Label1, Label2 } from 'baseui/typography';
import React, { FC } from 'react';

const getOverrides = ({ strong = false }: Props): BlockOverrides => {
  if (strong) {
    return {
      Block: {
        style: {
          fontWeight: 500,
        },
      },
    };
  }

  return {};
};

export type LabelType = 'default' | 'medium' | 'large';

interface Props {
  type?: LabelType;
  strong?: Boolean;
  children: React.ReactNode;
}

const Label: FC<Props> = props => {
  const { type = 'default', children } = props;

  const overrides = getOverrides(props);

  switch (type) {
    case 'default':
      return <Label2 overrides={overrides}>{children}</Label2>;
    case 'medium':
      return <Label2 overrides={overrides}>{children}</Label2>;
    case 'large':
      return <Label1 overrides={overrides}>{children}</Label1>;
  }
};
export default Label;
