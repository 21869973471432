import React, {useCallback, useMemo, useState} from 'react'

import * as ThornUI from '@ui'

import config from '../config'
import {SpotlightTheme} from '../themes/SpotlightTheme'

const ThemeProvider = ThornUI.Theme.Provider

export interface IAppProviderState {
  name: string
  updateUserSettings(userSettings: Partial<IUserMenuSettings>): void
  userSettings: IUserMenuSettings
}

interface IUserMenuSettings {
  isBlurEnabled: boolean
  isGrayscaleEnabled: boolean
  isTextScrambled: boolean
  isDefaultLocationsAutomatic: boolean
}

const IS_PRODUCTION = config.bugsnag.releaseStage === 'production'

const defaultSettings: IUserMenuSettings = {
  isBlurEnabled: !IS_PRODUCTION,
  isGrayscaleEnabled: !IS_PRODUCTION,
  isTextScrambled: !IS_PRODUCTION && !config.isCI,
  isDefaultLocationsAutomatic: true,
}

export const AppContext = React.createContext<IAppProviderState>({} as IAppProviderState)
export const AppConsumer = AppContext.Consumer

const localStorageEnabled = (() => {
  const uid = new Date().toISOString()
  let result: boolean
  try {
    localStorage.setItem(uid, uid)
    result = localStorage.getItem(uid) === uid
    localStorage.removeItem(uid)
    return !!result
  } catch (err) {
    return false
  }
})()

export const AppProvider: React.FC = ({children}) => {
  const sessionSettings: IUserMenuSettings | null = useMemo(() => {
    if (localStorageEnabled) {
      const settings = localStorage.getItem('userSettings')
      return settings ? JSON.parse(settings) : null
    } else {
      return null
    }
  }, [])
  const [userSettings, setUserSettings] = useState<IUserMenuSettings>({...defaultSettings, ...(sessionSettings || {})})

  const updateUserSettings = useCallback(
    (newUserSettings: Partial<IUserMenuSettings>) => {
      setUserSettings({
        ...userSettings,
        ...newUserSettings,
      })
      if (localStorageEnabled) {
        localStorage.setItem('userSettings', JSON.stringify({...userSettings, ...newUserSettings}))
      }
    },
    [userSettings],
  )

  return (
    <AppContext.Provider
      value={{
        name: 'Spotlight',
        updateUserSettings,
        userSettings,
      }}
    >
      <ThemeProvider theme={SpotlightTheme}>{children}</ThemeProvider>
    </AppContext.Provider>
  )
}
