import {ISource} from './types'
// default url and displayName to the siteName if we don't have a definition
export const getSourceDefinition = (siteName, sources): ISource => {
  const source = sources[siteName]
  return (
    source || {
      url: siteName,
      displayName: siteName,
    }
  )
}
