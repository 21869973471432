/**
 * Provides context for the currently active page.
 */
import React, {useContext, useEffect} from 'react'

import {SearchContext} from './SearchContext'

export interface IPage {
  pageTitle: string
  className?: string
}

const PageContext = React.createContext({})

export const Page: React.FC<IPage> = props => {
  const {searchPageTitleSuffix} = useContext(SearchContext)
  const titleParts = [props.pageTitle, searchPageTitleSuffix]

  useEffect(() => {
    document.title = titleParts.join(' ')
  }, [titleParts])

  return (
    // We have no PageContext values, yet.
    <PageContext.Provider value={{}}>
      <div className={props.className}>{props.children}</div>
    </PageContext.Provider>
  )
}
