import Bugsnag from '@bugsnag/js'
import {AccessToken, IDToken, OAuthResponseType, OktaAuth, OktaAuthOptions} from '@okta/okta-auth-js'
import {WidgetOptions} from '@okta/okta-signin-widget'
import {SUPPORT_EMAIL} from 'pages/ErrorPage'

import {colors} from '@ui/theme'
import {authUrl, clientId, issuer} from 'lib/API'

import config from '../config'

import browserHistory from './browserHistory'
import thaxios from './thaxios'

const oktaConfig: OktaAuthOptions = {
  tokenManager: {
    storage: 'localStorage',
    secure: true,
    expireEarlySeconds: 120, // this flag only applies to the localhost domain
    autoRenew: true,
  },
  authorizeUrl: `${authUrl}/oauth2/default/v1/authorize`,
  userinfoUrl: `${authUrl}/oauth2/default/v1/userinfo`,
  issuer: issuer,
  redirectUri: `${window.location.origin}/login`,
  postLogoutRedirectUri: `${window.location.origin}/login`,
  pkce: false,
  clientId,
}

const OKTA_RESPONSE_TYPE: OAuthResponseType[] = ['id_token', 'token']
const OKTA_SCOPES = ['openid', 'email', 'profile']

export const oktaAuth: OktaAuth = new OktaAuth(oktaConfig)

// Start the OktaAuth service for token management, such as auto-renewal
// See https://github.com/okta/okta-auth-js#running-as-a-service
oktaAuth.start().catch(err => {
  console.warn(err)
  Bugsnag.notify(err, event => {
    event.severity = 'warning'
  })
})

// The config used for the Okta login widget.
// See https://developer.okta.com/code/react/okta_react_sign-in_widget/#config
export const OKTA_SIGN_IN_CONFIG: WidgetOptions = {
  authClient: oktaAuth,
  baseUrl: authUrl,
  colors: {
    brand: colors.patina,
  },
  clientId,
  redirectUri: `${window.location.origin}/login`,
  logo: 'https://spotlight-public-assets.s3.amazonaws.com/images/Spotlight_logo_coral@2x.png',
  logoText: 'Spotlight',
  brandName: 'Spotlight',
  features: {
    rememberMe: false,
    registration: true,
  },
  helpSupportNumber: SUPPORT_EMAIL,
  helpLinks: {
    help: `mailto:${SUPPORT_EMAIL}`,
  },
  authParams: {
    pkce: oktaConfig.pkce,
    responseType: OKTA_RESPONSE_TYPE,
    scopes: OKTA_SCOPES,
  },
  registration: {
    click: () => {
      browserHistory.push('/request-access')
    },
  },
  /** https://github.com/okta/okta-signin-widget/blob/5.8/packages/@okta/i18n/src/properties/login.properties */
  i18n: {
    en: {
      'registration.signup.text': 'Request access',
      // Replace 'username' with 'email'
      'error.username.required': 'Please enter an email',
      'primaryauth.username.placeholder': 'Email',
      'primaryauth.username.tooltip': 'Email',
      'password.forgot.email.or.username.placeholder': 'Email',
      'password.forgot.email.or.username.tooltip': 'Email',
    },
  },
}

oktaAuth.tokenManager.on('error', err => {
  console.warn(err)
  Bugsnag.notify(err, event => {
    event.severity = 'warning'
  })
})

export const forgotPassword = async username => {
  if (config.skipAuthEmails) {
    console.warn('Skip auth emails is set: skipping email for "forgot password"')
    return
  }

  await oktaAuth.forgotPassword({
    username,
    factorType: 'EMAIL',
  })
}

export const verifyReset = async (recoveryToken: string) => {
  return oktaAuth.verifyRecoveryToken({recoveryToken})
}

export const setNewPassword = async (verification, newPassword) => {
  return verification.resetPassword({
    newPassword,
  })
}

export const getAuthTokens = async (): Promise<[IDToken, AccessToken] | null> => {
  const accessToken = (await oktaAuth.tokenManager.get('accessToken')) as AccessToken
  const idToken: IDToken = (await oktaAuth.tokenManager.get('idToken')) as IDToken
  if (!idToken || !accessToken) {
    return null
  }

  return [idToken, accessToken]
}

const deleteCookies = async () => thaxios.delete('/cookies')

export const logout = async () => {
  try {
    await deleteCookies()
    await oktaAuth.signOut()
  } catch (err) {
    Bugsnag.notify(err, event => {
      event.severity = 'warning'
    })
    console.warn(err)
  }
}

/** Captures the current URL for later after the user logs in. */
export const captureOriginalUrl = () => {
  oktaAuth.setOriginalUri(`${window.location.pathname}${window.location.search}`)
}
