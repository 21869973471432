export const primaryColors = {
  primary1: '#062028', // tickFillHover
  primary2: '#114150', // unused
  primary3: '#216073', // buttonPrimaryHOver
  primary4: '#377C91', // buttonPrimaryFill, tickFillSelected, buttonSecondaryText, checkbox bg color
  primary5: '#5194A9', // unused
  primary6: '#72AABB', // unused
  primary7: '#8CC3D4', // unused
  primary8: '#ACD9E7', // unused
  primary9: '#D1EDF5', // buttonSecondaryHover
  primary10: '#F3FAFC', // unused
};

export const neutralColors = {
  neutral1: '#464D59', // tagPositiveSolidFont, tagAccentSolidFont, tagNeutralSolidFont
  neutral2: '#5F6877', // Input - borderBottomColor isFocused
  neutral3: '#767F89', // ProfileThumbnail
  neutral4: '#ADB2B8', // unused
  neutral5: '#C8CCD0', // unused
  neutral6: '#D4D5D9', // unused
  neutral7: '#E4E5E7', // tagNeutralSolidBackground, Input isFocused (false)
  neutral8: '#F1F2F3', // unused
  neutral9: '#F4F6F7', // tabs(?)
  neutral10: '#FAFAFA', // Input backgroundColor
};

export const accentColors = {
  accent1: '#826301', // unused
  accent2: '#A38029', // unused
  accent3: '#C59B30', // unused
  accent4: '#D3AC4A', // unused
  accent5: '#DBBB6B', // Ekata - first posted / last posted
  accent6: '#E3CA8C', // unused
  accent7: '#EBD9AD', // unused
  accent8: '#F3E8CE', // Suspected spam banner
};

export const accent2Colors = {
  accent2_1: '#231B45', // unused
  accent2_2: '#35246B', // unused
  accent2_3: '#442A89', // unused
  accent2_4: '#5F37C0', // unused
  accent2_5: '#8559ED', // unused
  accent2_6: '#B37DFB', // unused
  accent2_7: '#C7B3FB', // unused
  accent2_8: '#DACFF9', // unused
};

export const positiveColors = {
  positive1: '#285333',
  positive2: '#3C7C4D',
  positive3: '#49975E',
  positive4: '#5AAF71',
  positive5: '#75BD88',
  positive6: '#91CAA0', // tagPositiveSolidBackground
  positive7: '#ACD7B8',
  positive8: '#C8E4D0',
  positive9: '#E3F2E7',
};

export const warningColors = {
  warning1: '#76322D',
  warning2: '#913D37',
  warning3: '#B14B43',
  warning4: '#C1635C',
  warning5: '#CD7F7A',
  warning6: '#D89C97',
  warning7: '#EBB8B5',
  warning8: '#EED4D3',
};

const palette = {
  primary: Object.values(primaryColors),
  neutral: Object.values(neutralColors),
  accent: Object.values(accentColors),
  accent2: Object.values(accent2Colors),
  positive: Object.values(positiveColors),
  warning: Object.values(warningColors),
};

const allColors = {
  ...primaryColors,
  ...neutralColors,
  ...accentColors,
  ...accent2Colors,
  ...positiveColors,
  ...warningColors,
};

type Colors = typeof allColors;
type ThemeColor = keyof Colors;

const getColor = (color: ThemeColor): string => allColors[color];

export { palette, ThemeColor, getColor };
