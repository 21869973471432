import { Button as BaseButton, ButtonProps, KIND, SIZE } from 'baseui/button';
import React, { FC, ForwardedRef, RefObject } from 'react';
import { primaryColors } from '../design/colors';
const { primary3 } = primaryColors;
const { assign } = Object;

const secondaryStyles = {
  borderTopStyle: 'solid',
  borderRightStyle: 'solid',
  borderBottomStyle: 'solid',
  borderLeftStyle: 'solid',

  borderTopWidth: '1px',
  borderRightWidth: '1px',
  borderBottomWidth: '1px',
  borderLeftWidth: '1px',
  borderColor: '#D2D9DA',
};

const primaryStyles = {
  ':hover': {
    backgroundColor: primary3,
  },
};

const defaultSize = {
  paddingTop: '12px',
  paddingBottom: '12px',
  minHeight: '44px',
  paddingLeft: '24px',
  paddingRight: '24px',
};

const compactSize = {
  paddingTop: '10px',
  paddingBottom: '10px',
  minHeight: '38px',
  paddingLeft: '16px',
  paddingRight: '16px',
};

const miniSize = {
  paddingTop: '6px',
  paddingBottom: '6px',
  minHeight: '30px',
  paddingLeft: '8px',
  paddingRight: '8px',
};

const getOverrides = (
  size?: SIZE[keyof SIZE],
  kind: KIND[keyof KIND] = 'primary',
  disabled?: boolean
) => {
  return {
    BaseButton: {
      style: () => {
        let style = {};

        switch (kind) {
          case 'primary':
            if (!disabled) {
              style = assign(style, primaryStyles);
            }

            break;
          case 'secondary':
            style = assign(style, secondaryStyles);
            break;
        }

        switch (size) {
          case 'compact':
            style = assign(style, compactSize);
            break;

          case 'mini':
            style = assign(style, miniSize);
            break;

          default:
            style = assign(style, defaultSize);
        }

        return style;
      },
    },
  };
};

type Props = ButtonProps & { forwardedRef?: ForwardedRef<HTMLButtonElement> };

const Button: FC<Props> = (props) => {
  const { forwardedRef, size, kind, disabled, ...baseProps } = props;

  const overrides = getOverrides(size, kind, disabled);

  return (
    <BaseButton
      ref={forwardedRef}
      overrides={overrides}
      disabled={disabled}
      size={size}
      kind={kind}
      {...baseProps}
    ></BaseButton>
  );
};

const ForwardedButton = React.forwardRef<RefObject<typeof Button>, Props>(
  // @ts-ignore allow null ref
  (props, ref) => <Button forwardedRef={ref} {...props} />
);
ForwardedButton.displayName = 'Button';

export default ForwardedButton;
