export const boxShadowBase = '0px 2px 4px 1px rgba(0, 0, 0, 0.1)'

export const SpotlightTheme = {
  components: {},
  defaultStyles: {
    borderRadius: '4px',
    boxShadow: {
      base: boxShadowBase,
      light: '0px 2px 4px 0px rgba(0, 0, 0, 0.15)',
      dark: '0px 2px 4px 1px rgba(0, 0, 0, 0.2)',
    },
  },
  typography: {
    fontFamilies: {
      primary: `'Helvetica Neue', sans-serif`,
      accent: `'Roboto Slab', 'Droid Serif', serif`,
    },
    fontSizes: {
      xs: '12px',
      sm: '14px',
      base: '16px',
      md: '18px',
      lg: '20px',
      xl: '24px',
    },
    lineHeights: {
      xs: '16px',
      sm: '18px',
      base: '20px',
      md: '40px',
      lg: '40px',
      xl: '40px',
    },
  },
  videoPlaceholderUrl: 'https://spotlight-public-assets.s3.amazonaws.com/images/video_placeholder.png',
}
