import Bugsnag from '@bugsnag/js'

import {Event} from './core'

/**
 * https://developers.google.com/analytics/devguides/collection/gtagjs/events
 */
export const gtagEvent = ({action, ...rest}: Pick<Event, 'action'>) => {
  if (!window.gtag) {
    console.warn(
      'window.gtag is not defined. This could mean your google analytics script has not loaded on the page yet.',
    )
    Bugsnag.notify(`window.gtag not defined for gtagEvent`, event => {
      event.severity = 'warning'
    })

    return
  }
  window.gtag('event', action, rest)
}
