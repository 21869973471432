import React, {useCallback, useEffect} from 'react'
import styled from 'styled-components'

import {Icon} from '@ui/Icon'
import {colors} from '@ui/theme'
import {tryParseErrorMessage} from 'lib/thaxios'
import {track} from 'lib/tracking/unauthedTracking'
import {IconName} from 'shared/types'

import AuthLayout from '../components/layouts/AuthLayout'
import {Page} from '../contexts/PageContext'

interface ErrorPageProps {
  pageTitle: string
  title: string
  titleIcon: IconName
  showRefreshAction?: boolean
}

const CenteredBox = styled.div`
  background-color: ${colors.pampas};
  border-radius: 4px;
  box-shadow: ${props => props.theme.defaultStyles.boxShadow.base};
  font-size: 14px;
  padding: 0 36px;
  width: 620px;
`

const Title = styled.h1`
  color: ${colors.ironside};
  font-size: 26px;
  margin: 20px 0;
`

const TitleIcon = styled(Icon)`
  bottom: 3px;
  margin-right: 8px;

  && {
    color: ${colors.chestnutrose};
    font-size: 24px !important;
  }
`

export const Paragraph = styled.p`
  color: ${colors.ironside};
  margin: 10px 0;
`

const FooterParagraph = styled(Paragraph)`
  font-style: italic;
  margin: 30px 0;
`
const Actions = styled.ul`
  margin: 0 0 20px 0;
`
const ActionItemContainer = styled.li`
  color: ${colors.ironside};
  list-style: none;
  margin: 0 0 4px 0;
`
const ActionItemIcon = styled(Icon)`
  bottom: 1px;
  margin-right: 6px;

  && {
    color: ${colors.cloudy};
    font-size: 16px;
  }
`
export const Anchor = styled.a`
  cursor: pointer;
  font-weight: 500;
`

const useRefresh = () =>
  useCallback(() => {
    window.location.reload()
  }, [])

const useGoBack = () =>
  useCallback(() => {
    window.history.back()

    // This is a hacky way to force the app to refresh and clear the error state.
    // We wait for 1 second for the URL to be updated.
    setTimeout(() => {
      window.location.reload()
    }, 1000)
  }, [])

const useGoToSearch = () =>
  useCallback(() => {
    window.location.href = '/search'
  }, [])

// This is be called anytime the backward or forward button in the browser is clicked
const handlePopState = () => {
  window.location.reload()
}

const usePopStateHandler = () => {
  useEffect(() => {
    window.addEventListener('popstate', handlePopState)
    return () => window.removeEventListener('popstate', handlePopState)
  }, [])
}

export const SUPPORT_EMAIL = 'support@spotlight.ngo'

const ErrorPage: React.FC<ErrorPageProps> = ({pageTitle, title, titleIcon, showRefreshAction}) => {
  const refresh = useRefresh()
  const goBack = useGoBack()
  const goToSearch = useGoToSearch()

  usePopStateHandler()

  return (
    <Page className="error-page" pageTitle={pageTitle}>
      <AuthLayout>
        <CenteredBox>
          <Title>
            <TitleIcon name={titleIcon} />
            {title}
          </Title>
          <Paragraph>We apologize for the inconvenience. Here are some actions you can take to proceed:</Paragraph>
          <Actions>
            {showRefreshAction && (
              <ActionItemContainer>
                <ActionItemIcon name="Refresh" />
                <Anchor onClick={refresh}>Refresh the page</Anchor>
              </ActionItemContainer>
            )}
            <ActionItemContainer>
              <ActionItemIcon name="ArrowBack" />
              <Anchor onClick={goBack}>Return to the previous page</Anchor>
            </ActionItemContainer>
            <ActionItemContainer>
              <ActionItemIcon name="Search" />
              <Anchor onClick={goToSearch}>Return to Search</Anchor>
            </ActionItemContainer>
          </Actions>
          <FooterParagraph>
            If this issue continues, please email <Anchor href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</Anchor> with
            a description of the error.
          </FooterParagraph>
        </CenteredBox>
      </AuthLayout>
    </Page>
  )
}

export const GenericErrorPage: React.FC<{error: Error}> = ({error}) => {
  const errorMessage = tryParseErrorMessage(error)
  const isDownForMaintenance = errorMessage && errorMessage.includes('maintenance')

  useEffect(() => {
    if (!isDownForMaintenance) {
      track('errorPageLoaded')
    }
  }, [isDownForMaintenance])

  return isDownForMaintenance ? (
    <MaintenancePage />
  ) : (
    <ErrorPage
      pageTitle="Spotlight - Error"
      title="Spotlight encountered an error"
      titleIcon="Warning"
      showRefreshAction
    />
  )
}

export const NotFoundPage: React.FC = () => (
  <ErrorPage pageTitle="Spotlight - Page Not Found" title="Page not found" titleIcon="Help" />
)

export const ForbiddenPage: React.FC = () => (
  <ErrorPage pageTitle="Spotlight - Forbidden" title="Admin access required" titleIcon="Lock" />
)

const MaintenancePage: React.FC = () => {
  usePopStateHandler()

  return (
    <Page className="error-page" pageTitle="Spotlight - Maintenance">
      <AuthLayout>
        <CenteredBox>
          <Title>
            <TitleIcon name="Wrench" />
            Spotlight is down for maintenance
          </Title>
          <Paragraph>
            We apologize for the inconvenience. Spotlight is down for routine maintenance and will be back online
            shortly.
          </Paragraph>
          <Actions>
            <ActionItemContainer>
              <ActionItemIcon name="Email" />
              <Anchor href={`mailto:${SUPPORT_EMAIL}`} target="_blank">
                Contact us for urgent requests
              </Anchor>
            </ActionItemContainer>
            <ActionItemContainer>
              <ActionItemIcon name="ArrowRightAlt" />
              <Anchor href="https://spotlight.ngo/" target="_blank">
                Learn more about Spotlight
              </Anchor>
            </ActionItemContainer>
          </Actions>
          <FooterParagraph>
            If you have any other questions, please contact us at{' '}
            <Anchor href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</Anchor>
          </FooterParagraph>
        </CenteredBox>
      </AuthLayout>
    </Page>
  )
}
