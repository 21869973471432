import {
  PLACEMENT,
  PopoverOverrides,
  StatefulPopover,
  StatefulPopoverProps,
} from 'baseui/popover';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

export const InfoContainer = styled.div<{ width?: string | number }>`
  background-color: #fff;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;

  border-radius: 4px;
  width: ${props => props.width};
`;

const getOverrides = (className: string): PopoverOverrides => {
  return {
    // need to override both elements for border-radius
    Body: {
      style: {
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        borderBottomLeftRadius: '4px',
        borderBottomRightRadius: '4px',
        // give minor boost to precedence over items such as ad text blur container
        zIndex: 10,
      },
      props: {
        className,
      },
    },
    Inner: {
      style: {
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        borderBottomLeftRadius: '4px',
        borderBottomRightRadius: '4px',
      },
    },
  };
};

interface Props extends StatefulPopoverProps {
  className?: string;
  width?: number | string;
  message: ((close: () => void) => ReactNode) | ReactNode;
}

const PopoverDropdown: FC<Props> = props => {
  const {
    message,
    width = '300px',
    className = '',
    children,
    ...baseProps
  } = props;

  const overrides = getOverrides(className);

  //pass the close event to message if user provides a function
  const PopoverContent = (close: () => void) => {
    if (typeof message == 'function') {
      return <InfoContainer width={width}>{message(close)}</InfoContainer>;
    } else return <InfoContainer width={width}>{message}</InfoContainer>;
  };

  return (
    <StatefulPopover
      overrides={overrides}
      popperOptions={{ modifiers: { flip: { enabled: false } } }}
      content={({ close }) => PopoverContent(close)}
      placement={PLACEMENT.bottomRight}
      popoverMargin={8}
      {...baseProps}
    >
      {children}
    </StatefulPopover>
  );
};

export default PopoverDropdown;
