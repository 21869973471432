import {ComponentCSSOverrides} from '../theme'

export function defaultValue<V extends (props: P) => T, P, T>(val: V, fallback: T) {
  return (props: P) => (typeof val(props) !== 'undefined' ? val(props) : fallback)
}

export function prop<Props, K extends keyof Props>(key: K) {
  return (props: Props): Props[K] => props[key]
}

export function componentTheme<Props, Theme extends {[key: string]: any}, T>(
  key: keyof ComponentCSSOverrides,
  fallback?: T,
) {
  return (props: Props & {theme: Theme}) =>
    props.theme && props.theme.components && typeof props.theme.components[key] !== 'undefined'
      ? props.theme.components[key]
      : fallback
}

export function theme<Props, Theme extends {[key: string]: any}, T>(key: keyof Theme, fallback?: T) {
  return (props: Props & {theme: Theme}) =>
    props.theme && typeof props.theme[key] !== 'undefined' ? props.theme[key] : fallback
}

function toArray<T>(arg: T) {
  return Array.isArray(arg) ? arg : [arg]
}

function clamp(num: number, min: number, max: number) {
  if (num < min) {
    return min
  }

  if (num > max) {
    return max
  }

  return num
}

export interface PaletteProps {
  theme: {[k: string]: any}
  palette?: string
  tone?: number
}

export function palette(keyOrTone?: string | number, toneOrDefaultValue?: any, fallback?: any) {
  return (props: PaletteProps) => {
    const key = typeof keyOrTone === 'string' ? keyOrTone : props.palette
    const tone =
      typeof keyOrTone === 'number'
        ? keyOrTone
        : typeof toneOrDefaultValue === 'number'
        ? toneOrDefaultValue
        : props.tone || 0
    const finalDefaultValue = toneOrDefaultValue !== tone ? toneOrDefaultValue : fallback

    if (!props.theme.palette || !key || !props.theme.palette[key]) {
      return finalDefaultValue
    }

    const tones: any[] = toArray(props.theme.palette[key])

    if (tone < 0) {
      return tones[clamp(tones.length + tone, 0, tones.length - 1)]
    }

    return tones[clamp(tone, 0, tones.length - 1)]
  }
}

function parseFunction<P>(props: P, test: (props: P) => boolean) {
  return Boolean(test(props))
}

function parseObject<P, T extends {[K in keyof P]: any}>(props: P, test: T) {
  const keys = Object.keys(test) as (string & keyof P)[]

  for (const key of keys) {
    const expected = test[key]
    const value = props[key]

    if (expected !== value) {
      return false
    }
  }

  return true
}

function parseString<P extends object, K extends keyof P>(props: P, test: K) {
  return Boolean(props[test])
}

const parseMap = {
  function: parseFunction,
  object: parseObject,
  // eslint-disable-next-line id-blacklist
  string: parseString,
}

type Needle<P> = ((props: P) => boolean) | {[K in keyof P]?: any} | string

export function ifProp<Props, Pass = undefined, Fail = undefined>(
  test: Needle<Props> | Needle<Props>[] | Record<string, any>,
  pass: Pass,
  fail?: Fail,
) {
  return <P = Props>(props: P) => {
    let result = true

    if (Array.isArray(test)) {
      const length = test.length
      let index = 0
      while (result && index < length) {
        result = parseMap[typeof test[index]](props, test[index])
        index += 1
      }
    } else {
      result = parseMap[typeof test](props, test)
    }

    return result ? pass : fail
  }
}
