/* eslint-disable import/no-unassigned-import */

import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'react-tippy/dist/tippy.css'
import 'date-time-format-timezone'
import '@perf-tools/performance'
import './react-app-env.d.ts'
import './polyfills.ts'

import React from 'react'
import ReactDOM from 'react-dom'
import smoothscroll from 'smoothscroll-polyfill'

import App from './App'

smoothscroll.polyfill()

ReactDOM.render(<App />, document.getElementById('root'))
