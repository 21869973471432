import { Input as BaseInput, InputOverrides, InputProps } from 'baseui/input';
import { neutralColors } from '../../design/colors';
import React, { FC } from 'react';

export type InputType = 'large' | 'standard' | 'small';
const { neutral1, neutral2, neutral7 } = neutralColors;

const INPUT_TYPE_OVERRIDES = {
  large: {
    height: '48px',
    fontSize: '16px',
  },
  standard: {
    height: '36px',
    fontSize: '14px',
  },
  small: {
    height: '32px',
    fontSize: '14px',
  },
};

const getInputOverrides = (
  className: string,
  inputType: InputType
): InputOverrides => {
  const typeOverrides = INPUT_TYPE_OVERRIDES[inputType];

  return {
    Input: {
      style: {
        backgroundColor: '#FAFAFA',
        fontFamily: `'Helvetica Neue', sans-serif`,
        paddingLeft: '8px',
        // set line height to 16px consistent with font size 16px for IE11
        lineHeight: '16px',
        fontSize: typeOverrides.fontSize,
      },
    },
    StartEnhancer: {
      style: {
        marginLeft: 0,
        paddingLeft: 0,
        backgroundColor: '#FAFAFA',
      },
    },
    Root: {
      props: {
        className,
      },
      style: ({ $isFocused }) => {
        return {
          height: typeOverrides.height,
          fontColor: neutral1,
          // can't use short-hand since we're overriding atomic css definitions
          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px',
          borderBottomLeftRadius: '4px',
          borderBottomRightRadius: '4px',
          backgroundColor: '#FAFAFA',
          borderBottomColor: $isFocused ? neutral2 : neutral7,
          borderLeftWidth: '1px',
          borderRightWidth: '1px',
          borderTopWidth: '1px',
          borderBottomWidth: '1px',
        };
      },
    },
  };
};

interface Props extends InputProps {
  inputType?: InputType;
  className?: string;
}

const Input: FC<Props> = props => {
  const { inputType = 'standard', className = '', ...baseProps } = props;

  const overrides = getInputOverrides(className, inputType);

  return <BaseInput overrides={overrides} {...baseProps} />;
};
export default Input;
