import styled from 'styled-components'

import {componentTheme} from '../_utils'
import {colors} from '../theme'

export const ModuleTitle = styled.h3`
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: ${colors.ironside};

  // TODO: move this to another component
  & .count {
    font-weight: bold;
    color: ${colors.leather};
  }

  ${componentTheme('ModuleTitle')}
`

ModuleTitle.displayName = 'ModuleTitle'
