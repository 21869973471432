import {useOktaAuth} from '@okta/okta-react'
import {usePrevious} from 'hooks/usePrevious'
import React, {useCallback, useEffect, useMemo, useState} from 'react'

import {getAuthTokens} from 'lib/authenticate'
import browserHistory from 'lib/browserHistory'
import thaxios from 'lib/thaxios'
import {setGAUser} from 'lib/tracking/core'
import {AgencyCountry, IClientUserDetailsResponse, ILocationFilterCheckboxValue} from 'shared/types'

import {emitError} from '../ErrorListener'
import {FullScreenLoading} from '../pages/FullScreenLoadingPage'

export interface User {
  userId: string
  name: string
  initials: string
  email: string
  agencyCode: string
  agencyName: string
  agencyCountry: AgencyCountry
  hasAcknowledgedDefaultLocations: boolean
  defaultLocations: ILocationFilterCheckboxValue[]
  created_at: any
}

export interface AuthData {
  isAdmin: boolean
  user: User
  updateDefaultLocations: (newDefaultLocations: ILocationFilterCheckboxValue[]) => void
  updateUserAcknowledgmentDefaultLocations: () => void
}

const guestUser = {
  userId: '',
  name: '',
  initials: '',
  email: '',
  agencyCode: '',
  agencyName: '',
  agencyCountry: 'US' as AgencyCountry,
  hasAcknowledgedDefaultLocations: false,
  defaultLocations: [],
  created_at: '',
}

const getUserDetails = async () => thaxios.get(`/user/details`)

export const AuthenticationContext = React.createContext<AuthData>({} as AuthData)

export const AuthenticationProvider: React.FC = ({children}) => {
  const {authState} = useOktaAuth()
  const isAuthenticated = !!authState?.isAuthenticated
  const prevIsAuthenticated = usePrevious(isAuthenticated)
  const [isLoading, setIsLoading] = useState(true)
  const [isAdmin, setIsAdmin] = useState(false)
  const [user, setUser] = useState<User>(guestUser)

  // Update User Default Location List
  const updateDefaultLocations = useCallback(
    (newDefaultLocations: ILocationFilterCheckboxValue[]) => {
      setUser({
        ...user,
        defaultLocations: newDefaultLocations,
      })
    },
    [user],
  )

  // Update User Default Location Acknowledgment
  const updateUserAcknowledgmentDefaultLocations = useCallback(() => {
    setUser({
      ...user,
      hasAcknowledgedDefaultLocations: true,
    })
  }, [user])

  // Fetch current user whenever Okta's authentication state changes
  useEffect(() => {
    setIsLoading(true)
    ;(async () => {
      const userData = await getAuthTokens()
      if (isAuthenticated && userData && userData[0]) {
        const {name, email} = userData[0].claims
        const userDetails: IClientUserDetailsResponse = await getUserDetails()
        const {
          userId,
          agencyId,
          hasAcknowledgedDefaultLocations,
          created_at,
          acceptedCurrentEula,
          ...otherDetails
        } = userDetails

        if (!acceptedCurrentEula) {
          browserHistory.push(`/eula`)
        }

        if (!name || !email) {
          const message = `User ${userId} is missing name, email`
          throw new Error(message)
        }
        setUser({
          userId,
          name,
          email,
          hasAcknowledgedDefaultLocations: !!hasAcknowledgedDefaultLocations,
          created_at,
          ...otherDetails,
        })
        setGAUser(userId, agencyId)
        setIsAdmin(!!userData[0].claims.showAdminMenu)
      } else {
        setIsAdmin(false)
        setUser(guestUser)
      }
      setIsLoading(false)
    })().catch(emitError)
  }, [isAuthenticated])

  // Build context object
  const authData = useMemo(
    () => ({
      isAdmin,
      updateDefaultLocations,
      updateUserAcknowledgmentDefaultLocations,
      user,
    }),
    [isAdmin, updateDefaultLocations, updateUserAcknowledgmentDefaultLocations, user],
  )

  // display a simple loading UI while loading
  const isAuthenticatedChanging = isAuthenticated !== prevIsAuthenticated
  if (isLoading || isAuthenticatedChanging) {
    return <FullScreenLoading />
  }

  return <AuthenticationContext.Provider value={authData}>{children}</AuthenticationContext.Provider>
}
