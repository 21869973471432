import * as Box from './Box'
import * as Button from './Button'
import * as Checkbox from './Checkbox'
import * as Icon from './Icon'
import * as InlineBlock from './InlineBlock'
import * as ListModule from './ListModule'
import * as Module from './Module'
import * as theme from './theme'
import * as TitleBar from './TitleBar'

export const Components = {
  Box,
  Button,
  Checkbox,
  Icon,
  InlineBlock,
  ListModule,
  Module,
  TitleBar,
}

export const Theme = theme
