import React, { FC, ReactElement } from 'react';
import styled, { css } from 'styled-components';
import { neutralColors } from '../design/colors';

export type ThumbnailSize = 'small' | 'medium' | 'large';

export interface Props {
  size: ThumbnailSize;
  className?: string;
  imageUrl?: string;
  defaultContent?: ReactElement;
}

const DefaultContainer = styled.div<{ usePlaceHolder: boolean }>`
  border-radius: 50%;
  height: 60px;
  width: 60px;
  flex: none;

  ${props =>
    props.usePlaceHolder &&
    css`
      align-items: center;
      background-color: #e4e5e7;
      display: flex;
      justify-content: center;
      position: relative;

      &::before {
        border-radius: 50%;
        content: ' ';
        display: block;
        position: absolute;
      }

      svg {
        color: ${neutralColors.neutral3};
        font-size: 16px;
        height: 16px;
        width: 16px;
      }
    `}
`;

const SmallContainer = styled(DefaultContainer)`
  height: 40px;
  width: 40px;
`;

const LargeContainer = styled(DefaultContainer)`
  height: 100px;
  width: 100px;

  ${props =>
    props.usePlaceHolder &&
    css`
      & > svg {
        color: ${neutralColors.neutral3};
        font-size: 21px;
        height: 21px;
        width: 21px;
      }
    `}
`;

const ProfileImage = styled.div<{ imageUrl: string }>`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: url(${props => props.imageUrl});
  background-size: cover;
`;

const getContainer = (size: ThumbnailSize) => {
  switch (size) {
    case 'small':
      return SmallContainer;
    case 'large':
      return LargeContainer;
    case 'medium':
    default:
      return DefaultContainer;
  }
};

/**
 * The thumbnail can show an image or, if an image is not available,
 * display a piece of default content inside of the thumbnail circle. The default content is expected to
 * be an SVG and should be automatically sized and positioned correctly.
 * Other types of content may require some additional styling to display correctly.
 */
const ProfileThumbnail: FC<Props> = ({
  imageUrl,
  defaultContent,
  size,
  className = '',
}) => {
  let childComponent;
  if (!!imageUrl && imageUrl?.length !== 0) {
    childComponent = <ProfileImage imageUrl={imageUrl} />;
  } else if (!!defaultContent) {
    childComponent = defaultContent;
  } else {
    throw new Error(
      'You must supply either an image URL or a default component for the ProfileThumbnail.'
    );
  }

  const Container = getContainer(size);

  return (
    <Container usePlaceHolder={!imageUrl} className={className}>
      {childComponent}
    </Container>
  );
};

export default ProfileThumbnail;
