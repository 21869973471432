import moment from 'moment'

import {FILTER_CATEGORIES, isCheckboxFilter, isRangeFilter} from 'shared/filterCategories'
import {IFilterCheckboxValue, IFilterRangeValue, IFilterValue} from 'shared/types'

import {dateFormats, makeOxfordList} from './formats'

export const countAppliedFilters = (filters: IFilterValue[] = [], category: string): number => {
  let count = 0

  for (const filter of filters) {
    if (filter.category === category) {
      if (isCheckboxFilter(filter)) {
        count += filter.checked ? 1 : 0
      } else if (isRangeFilter(filter)) {
        count += filter.min ? 1 : 0
        count += filter.max ? 1 : 0
      } else {
        throw Error('Not implemented')
      }
    }
  }

  return count
}

const buildPageTitleForCheckboxFilterGroup = (
  filters: IFilterCheckboxValue[],
  inclusionText: string,
  exclusionText: string,
): string[] => {
  const included = filters.filter(f => !f.isNegated).map(f => f.name)
  const excluded = filters.filter(f => f.isNegated).map(f => f.name)
  const titleParts: string[] = []
  if (included.length) {
    titleParts.push(`${inclusionText} ${makeOxfordList(included, 'or')}`)
  }
  if (excluded.length) {
    titleParts.push(`${exclusionText} ${makeOxfordList(excluded, 'or')}`)
  }
  return titleParts
}

const buildPageTitleForRangeFilterGroup = (filter: IFilterRangeValue | undefined, inclusionText: string) => {
  if (!filter) {
    return []
  }

  let text = inclusionText

  if (filter.min) {
    text += ` from ${filter.category === 'dateRange' ? moment(filter.min).format(dateFormats.short) : filter.min}`
  }

  if (filter.max) {
    text += ` to ${filter.category === 'dateRange' ? moment(filter.max).format(dateFormats.short) : filter.max}`
  }

  return [text]
}

export const buildPageTitleForFilters = (filters: IFilterValue[]) => {
  let titleParts: string[] = []

  for (const [category, description] of Object.entries(FILTER_CATEGORIES)) {
    // hide filters that have no user-facing text
    if (!description.pageTitlePrefix) {
      continue
    }

    if (description.type === 'checkbox') {
      titleParts = titleParts.concat(
        buildPageTitleForCheckboxFilterGroup(
          filters.filter(f => f.category === category) as IFilterCheckboxValue[],
          description.pageTitlePrefix,
          description.pageTitleNegatedPrefix,
        ),
      )
    } else if (description.type === 'range') {
      titleParts = titleParts.concat(
        buildPageTitleForRangeFilterGroup(
          filters.filter(f => f.category === category)[0] as IFilterRangeValue | undefined,
          description.pageTitlePrefix,
        ),
      )
    } else {
      throw Error('Not implemented')
    }
  }

  return ` ${makeOxfordList(titleParts, 'and')}`
}
