import isPropValid from '@emotion/is-prop-valid'

export function pickHTMLProps<P extends object>(props: P) {
  const filteredProps: Partial<P> = {}

  // eslint-disable-next-line no-restricted-syntax
  for (const prop in props) {
    if (isPropValid(prop)) {
      filteredProps[prop] = props[prop]
    }
  }

  return filteredProps
}
