import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import React from 'react'

import config from '../config'

export const initializeBugsnag = () => {
  Bugsnag.start({
    apiKey: config.bugsnag.key,
    releaseStage: config.bugsnag.releaseStage,
    enabledReleaseStages: ['staging', 'production', 'production-preview'],
    appVersion: process.env.REACT_APP_BUGSNAG_APP_VERSION || 'development',
    plugins: [new BugsnagPluginReact(React)],
  })
}
