// eslint-disable-next-line no-restricted-imports
import {isEmpty, uniqBy} from 'lodash-es'
import React, {useEffect, useReducer, useState} from 'react'

import {isCheckboxFilter, isRangeFilter} from 'shared/filterCategories'
import {IFilterCheckboxValue, IFilterRangeValue, IFilterValue, ISearchOptions} from 'shared/types'

export interface IFiltersContext {
  checkFilter(filter: IFilterCheckboxValue, isNegated?: boolean): void
  uncheckFilter(filter: IFilterCheckboxValue, isNegated?: boolean): void
  setRangeFilter(filter: IFilterRangeValue): void
  getFiltersByCategory(category: string): IFilterValue[]
  setFiltersByCategory(category: string, filters: IFilterValue[], isUpdatePending: boolean): void
}

export const FiltersContext = React.createContext<IFiltersContext>({} as IFiltersContext)

export interface IFiltersProviderProps {
  searchOptions: ISearchOptions
  // all filters are sent after X milliseconds of no changes
  onFiltersUpdateEvent(filter: IFilterValue[]): void
}

const getAppliedFilters = (filters: IFilterValue[], searchOptions) => {
  // We check 'searchOptions' for any filters that may be
  // missing because their API requests haven't returned yet.
  const allFilters = uniqBy(filters.concat(searchOptions.filters || []), 'id')

  const appliedFilters: IFilterValue[] = []

  for (const filter of allFilters) {
    if (isCheckboxFilter(filter)) {
      if (filter.checked) {
        appliedFilters.push(filter)
      }
    } else if (isRangeFilter(filter)) {
      if (filter.min || filter.max) {
        appliedFilters.push(filter)
      }
    } else {
      throw Error('Not implemented')
    }
  }

  return appliedFilters
}

interface IUpdateFiltersAction {
  category: string
  updatedFilters: IFilterValue[]
}

// replaces filters by category
const filtersReducer = (filters, action: IUpdateFiltersAction) => {
  return filters.filter(f => f.category !== action.category).concat(action.updatedFilters)
}

export const FiltersProvider: React.FC<IFiltersProviderProps> = ({searchOptions, onFiltersUpdateEvent, children}) => {
  const [isUpdatePending, setIsUpdatePending] = useState(false)
  const [filters, dispatchfilters] = useReducer(filtersReducer, [])

  const getFiltersByCategory = category => filters.filter(f => f.category === category)

  const setFiltersByCategory = (category, updatedFilters, isUserInteraction) => {
    dispatchfilters({category, updatedFilters})
    if (isUserInteraction) {
      setIsUpdatePending(true)
    }
  }

  const modifyCheckboxFilter = (filter: IFilterCheckboxValue, checked: boolean, isNegated: boolean) => {
    const newFilters = getFiltersByCategory(filter.category)
    const index = newFilters.findIndex(f => f.id === filter.id)
    if (index !== -1) {
      // it was toggled in some way, positively or negatively
      newFilters.splice(index, 1, {
        ...filter,
        isNegated,
        checked,
        count: newFilters[index].count,
      })
    } else {
      // this filter was added
      newFilters.unshift({
        ...filter,
        checked,
      })
    }
    setFiltersByCategory(filter.category, newFilters, true)
  }

  const checkFilter = (filter: IFilterCheckboxValue, isNegated?: boolean) => {
    modifyCheckboxFilter(filter, true, !!isNegated)
  }

  const uncheckFilter = (filter: IFilterCheckboxValue, isNegated?: boolean) => {
    modifyCheckboxFilter(filter, false, !!isNegated)
  }

  const setRangeFilter = (filter: IFilterRangeValue) => {
    setFiltersByCategory(filter.category, [filter], true)
  }

  // invoke 'onFiltersUpdateEvent' once user stops interacting with filters
  useEffect(() => {
    if (!isUpdatePending || !searchOptions) {
      return
    }

    setIsUpdatePending(false)

    const appliedFilters = getAppliedFilters(filters, searchOptions)

    if (isEmpty(searchOptions.filters) && isEmpty(appliedFilters)) {
      return
    }

    onFiltersUpdateEvent(appliedFilters)
  }, [filters, isUpdatePending, onFiltersUpdateEvent, searchOptions])

  return (
    <FiltersContext.Provider
      value={{
        checkFilter,
        uncheckFilter,
        setRangeFilter,
        getFiltersByCategory,
        setFiltersByCategory,
      }}
    >
      {children}
    </FiltersContext.Provider>
  )
}
