// Wrapper around axios, to set withCredentials and baseURL and
import axios, {AxiosError, AxiosRequestConfig, CancelToken, CancelTokenSource} from 'axios'
import axiosRetry, {exponentialDelay} from 'axios-retry'

import {getAuthTokens} from '../lib/authenticate'

import API from './API'

// Tolerate transient network errors (like ECONNRESET)
axiosRetry(axios, {retryDelay: exponentialDelay})

const CANCEL_TOKEN_SOURCES: Record<string, CancelTokenSource> = {}

export const getCancelToken = (id: string): CancelToken | undefined => {
  const source = CANCEL_TOKEN_SOURCES[id]
  if (source) {
    return source.token
  }

  const newSource = axios.CancelToken.source()
  CANCEL_TOKEN_SOURCES[id] = newSource
  return newSource.token
}

export const cancelRequests = (id: string, message: string) => {
  const source = CANCEL_TOKEN_SOURCES[id]
  if (source) {
    // Delete the token so that no subsequent requests will use it.
    // This would otherwise happen for any requests using the same identifier, like searchId value of DEFAULT_ID.
    // typescript-eslint/no-dynamic-delete
    // eslint-disable-next-line
    delete CANCEL_TOKEN_SOURCES[id]

    source.cancel(message)
  }
}

export const cancelAllRequests = (message: string) => {
  for (const id of Object.keys(CANCEL_TOKEN_SOURCES)) {
    cancelRequests(id, message)
  }
}

const GLOBAL_CONFIG = {
  baseURL: process.env.NODE_ENV === 'test' ? 'http://localhost' : API.url,
}

const getAuthHeaders = async () => {
  const tokens = await getAuthTokens()
  return tokens ? {Authorization: `Bearer ${tokens[1].accessToken}`} : {}
}

const request = async (config: AxiosRequestConfig) => {
  const headers = {
    ...(await getAuthHeaders()),
    ...config.headers,
  }

  const response = await axios.request({
    ...GLOBAL_CONFIG,
    cancelToken: getCancelToken('DEFAULT_CANCEL_TOKEN'),
    ...config,
    headers,
  })
  return response.data
}

const get = (url: string, config?: AxiosRequestConfig) =>
  request({
    method: 'get',
    url,
    ...config,
  })

// For direct AWS calls which forbid authorization headers
const getWithoutAuthHeaders = async (url: string, config?: AxiosRequestConfig) => {
  const res = await axios.request({
    method: 'get',
    url,
    ...config,
    ...GLOBAL_CONFIG,
  })
  return res.data
}

const post = (url: string, config: AxiosRequestConfig) =>
  request({
    method: 'post',
    url,
    ...config,
  })

const put = (url: string, config: AxiosRequestConfig) =>
  request({
    method: 'put',
    url,
    ...config,
  })

const deleteMethod = (url: string, config?: AxiosRequestConfig) =>
  request({
    method: 'delete',
    url,
    ...config,
  })

export default {
  delete: deleteMethod,
  get,
  getWithoutAuthHeaders,
  post,
  put,
  request,
}

// Get the status code for an AxiosError,
// or 'false' if it's not an AxiosError.
export const tryParseStatusCode = (err: Error): number | null => {
  const response = (err as AxiosError).response
  return response ? response.status : null
}

export const tryParseErrorMessage = (err: Error): string | null => {
  const response = (err as AxiosError).response
  return response && typeof response.data === 'string' ? response.data : null
}
